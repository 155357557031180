/*yearOfBirth: Int!
gender: String!
ethnicity: String!
education: String!
employment: String!
householdIncome: String!
industry: String
jobRole: String
householdComp: String
*/
import React, { useState } from "react";
import {
  Grid,
  Tooltip,
  FormControl,
  Checkbox,
  Button,
  FormControlLabel,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Divider,
  Typography,
} from "@mui/material";
// css
import Styles from "./../assets/styles/styles";
import { AnswerText, HeaderText2 } from "./../assets/styles/sxStyles";
import "../App.css";
import TermAndConditions from "../components/TermAndConditions";
import { createRegisteredUser } from "../graphql/mutations";
import { panelistIdByFirtLastInitialIndex } from "../graphql/customs";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";

const genders = [
  "Man",
  "Woman",
  "Non-binary",
  "Self-Identify",
  "Prefer not to say",
];
const ethnicities = [
  "American Indian or Alaska Native",
  "Asian",
  "Black or African American",
  "Hispanic or Latinx",
  "Middle Eastern or North African",
  "Native Hawaiian or other Pacific Islander",
  "White",
  "Self-Identify",
  "Prefer not to say",
];
const educations = [
  "Some high school",
  "High school graduate",
  "Some college",
  "College graduate",
  "Post graduate coursework",
  "Prefer not to say",
];
const employments = [
  "Employed full time (30 hours +)",
  "Employed part time",
  "Temporarily unemployed",
  "Retired",
  "Homemaker",
  "Student",
  "Prefer not to say",
];
const householdIncomes = [
  "Less than $25,000",
  "$25,000 to $49,999",
  "$50,000 to $74,999",
  "$75,000 to $99,999",
  "$100,000 to $124,999",
  "$125,000 to $149,999",
  "$Over $150,000",
  "Prefer not to say",
];

const industries = [
  "Agriculture",
  "Accounting",
  "Advertising",
  "Aerospace",
  "Airline",
  "Apparel & Accessories",
  "Automotive",
  "Banking",
  "Broadcasting",
  "Brokerage",
  "Biotechnology",
  "Call Centers",
  "Chemical",
  "Computer",
  "Consulting",
  "Consumer Products",
  "Cosmetics",
  "Defense",
  "Department Stores",
  "Education",
  "Electronics",
  "Energy",
  "Entertainment & Leisure",
  "Financial Services",
  "Food, Beverage & Tobacco",
  "Grocery",
  "Health Care",
  "Investment Banking",
  "Legal",
  "Manufacturing",
  "Motion Picture & Video",
  "New paper Publishers",
  "Pharmaceuticals",
  "Private Equity",
  "Publishing",
  "Real Estate",
  "Retail & Wholesale",
  "Securities Exchanges",
  "Service",
  "Software",
  "Sports",
  "Technology",
  "Telecommunications",
  "Television",
  "Transportation",
  "Trucking",
  "Venture Capital",
];
const householdComps = [
  "Solo",
  "With parent(s)",
  "With roommate(s)",
  "With partner/spouse",
  "With parter/spouse + child",
  "With child under 18",
  "Other",
];
const Registration = () => {
  const classes = Styles();
  const [firstName, setFirstName] = useState("");
  const [lastInitial, setLastInitial] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [SIGender, setSIGender] = useState("");
  const [SIEthnicity, setSIEthnicity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [gender, setGender] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [education, setEducation] = useState("");
  const [employment, setEmployment] = useState("");
  const [householdComp, setHouseholdComp] = useState("");
  const [householdIncome, setHouseholdIncome] = useState("");
  const [industry, setIndustry] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsScrolledDown, setTermsScrolledDown] = useState(false);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [finished, setFinished] = useState(false);

  const onSubmit = async () => {
    //fetch index
    const firstNameLastInitial = `${firstName
      .trim()[0]
      .toUpperCase()}${firstName.trim().slice(1)}${lastInitial
      .trim()[0]
      .toUpperCase()}`;

    let theIndex = 1;
    const data = await API.graphql({
      query: panelistIdByFirtLastInitialIndex,
      variables: {
        pxFirstLastInitial: firstNameLastInitial,
        limit: 1,
        sortDirection: "DESC",
      },
      authMode: "AWS_IAM", //"AMAZON_COGNITO_USER_POOLS",
    });
    console.log("data", data);
    if (data.data.panelistIdByFirtLastInitialIndex.items.length > 0)
      theIndex =
        data.data.panelistIdByFirtLastInitialIndex.items[0].pxIndex + 1;

    const registeredUser = {
      email: user.attributes.email,
      pxFirstLastInitial: firstNameLastInitial,
      pxIndex: theIndex,
      totalRewardThisYear: 0.0,
      active: true,
      yearOfBirth: yearOfBirth,
      gender: gender === "Self-Identify" ? SIGender : gender,
      ethnicity: ethnicity === "Self-Identify" ? SIEthnicity : ethnicity,
      education: education,
      employment: employment,
      householdIncome: householdIncome,
      householdComp: householdComp,
      industry: industry === "" ? null : industry,
      lastDemographicUpdate: new Date().toISOString().substring(0, 10),
    };
    const userData = await API.graphql({
      query: createRegisteredUser,
      variables: { input: registeredUser },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    console.log("userData", userData);
    if (userData.data.createRegisteredUser) {
      window.localStorage.clear();
      setFinished(true);
    }
  };

  const isSubmitValid = () => {
    return (
      termsChecked &&
      ((gender !== "" && gender !== "Self-Identify") ||
        (gender === "Self-Identify" && SIGender !== "")) &&
      ((ethnicity !== "" && ethnicity !== "Self-Identify") ||
        (ethnicity === "Self-Identify" && SIEthnicity !== "")) &&
      firstName !== "" &&
      lastInitial !== "" &&
      yearOfBirth !== "" &&
      zipCode !== "" &&
      education !== "" &&
      employment !== "" &&
      householdComp !== "" &&
      householdIncome !== ""
    );
  };

  const whySubmitDisabled = () => {
    const missing = [];
    if (firstName === "") missing.push("First Name");
    if (lastInitial === "") missing.push("Last Name Initial");
    if (yearOfBirth === "") missing.push("Year of birth");
    if (zipCode === "") missing.push("Zip Code");
    if (gender === "") missing.push("Gender");
    if (gender === "Self-Identify" && SIGender === "")
      missing.push("Self identified Gender");
    if (ethnicity === "") missing.push("Race or Ethnicity");
    if (ethnicity === "Self-Identify" && SIEthnicity === "")
      missing.push("Self identified Race or Ethnicity");
    if (education === "") missing.push("Education");
    if (employment === "") missing.push("Employment");
    if (householdComp === "") missing.push("Household Composition");
    if (householdIncome === "") missing.push("Household Income");

    return (
      (termsChecked ? "" : "Please accept Terms and Conditions.\n") +
      (missing.length > 0
        ? `Required fields missing: ${missing.join(", ")}.`
        : "")
    );
  };
  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handleChangeLastInitial = (e) => {
    setLastInitial(e.target.value);
  };
  const handleChangeYearOfBirth = (e) => {
    setYearOfBirth(e.target.value);
  };
  const handleChangeSIGender = (e) => {
    setSIGender(e.target.value);
  };
  const handleChangeSIEthnicity = (e) => {
    setSIEthnicity(e.target.value);
  };
  const handleChangeZipCode = (e) => {
    setZipCode(e.target.value);
  };
  const handleChangeGender = (e) => {
    setGender(e.target.value);
  };
  const handleChangeEthnicity = (e) => {
    setEthnicity(e.target.value);
  };
  const handleChangeEducation = (e) => {
    setEducation(e.target.value);
  };
  const handleChangeEmployment = (e) => {
    setEmployment(e.target.value);
  };
  const handleChangeHouseholdComp = (e) => {
    setHouseholdComp(e.target.value);
  };
  const handleChangeHouseholdIncome = (e) => {
    setHouseholdIncome(e.target.value);
  };
  const handleChangeIndustry = (e) => {
    setIndustry(e.target.value);
  };

  return (
    <div className="registration-page">
      {finished ? (
        <div className="card">
          <div className="container" style={{ flexDirection: "column" }}>
            <Typography sx={HeaderText2}>
              Thank you for registering as a Pyxis panelist. We will contact you
              to propose to participate in paid studies when your profile
              matches.
            </Typography>
          </div>
        </div>
      ) : (
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <div style={{ height: "12px" }}>&nbsp;</div>
          <Grid item xs={12} md={12}>
            <Typography sx={{ m: 1 }} variant="h6" gutterBottom>
              About your data:
            </Typography>
            <Typography
              gutterBottom
              variant="subtitle1"
              sx={{ m: 1 }}
              textAlign={"justify"}
            >
              We use demographic data to recruit relevant quandidates for
              studies. We strive to protect your privacy, not asking for last
              name or full date of birth. We use your verified email to invite
              you to screener questions, studies, or to send rewards. We don't
              communicate your email with researchers or any third party. We
              collect facial biometric data to make sure videos you submit are
              coming from you, and you are not creating multiple accounts. When
              participating to studies, videos are rated, leading to an internal
              scoring system that will be used to affect the probability of
              being recruited in paid studies. High score will be associated
              with quality of videos (good lighting and audio condition),
              information provided being genuine and detailed, demographics
              information being correct, and there was no tentative to abuse the
              system by creating multiple accounts. Other criteria may be
              introduced in the future.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography sx={{ m: 1 }} variant="h6" gutterBottom>
              Terms and Conditions:
            </Typography>
            <TermAndConditions
              onScrolledDown={() => setTermsScrolledDown(true)}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              sx={{ m: 0, width: "100%", textAlign: "center" }}
              control={
                <Checkbox
                  checked={termsChecked}
                  disabled={!termsScrolledDown}
                  onChange={() => setTermsChecked(!termsChecked)}
                />
              }
              label="Please scroll down then click the checkbox to accept the terms and conditions before proceeding"
            />
          </Grid>

          <Grid item xs={12} md={9}>
            <TextField
              sx={{ m: 1, width: "100%" }}
              id="firstName"
              label="First Name"
              value={firstName}
              onChange={handleChangeFirstName}
              helperText="Required. Researchers will know you by your first name and last name initial."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              sx={{ m: 1, width: "100%" }}
              id="lastInitial"
              label="Last Name initial"
              value={lastInitial}
              onChange={handleChangeLastInitial}
              helperText="Required."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ m: 1, width: "100%" }}
              id="yearOfBirth"
              type="number"
              label="Year of birth"
              value={yearOfBirth}
              onChange={handleChangeYearOfBirth}
              helperText="Required. 4 digits year of birth. We respect your privacy and only ask for year of birth."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={{ m: 1, width: "100%" }}
              id="zipCode"
              type="number"
              label="Zip Code"
              value={zipCode}
              onChange={handleChangeZipCode}
              helperText="Required. 5 digits zip code. Currently only supporting US based panelists."
            />
          </Grid>
          <Grid
            item
            md={gender === "Self-Identify" ? 2 : 6}
            xs={gender === "Self-Identify" ? 6 : 12}
          >
            <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="gender-label">Gender</InputLabel>
              <Select
                labelId="gender-label"
                id="gender-select-helper"
                value={gender}
                label="Gender"
                onChange={handleChangeGender}
              >
                {genders.map((g, k) => (
                  <MenuItem value={g} key={k}>
                    {g}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required.</FormHelperText>
            </FormControl>
          </Grid>
          {gender === "Self-Identify" ? (
            <Grid item md={4} xs={6}>
              <TextField
                sx={{ m: 1, width: "100%" }}
                id="genderSelfIdentify"
                label="Self identified gender"
                value={SIGender}
                onChange={handleChangeSIGender}
                helperText="Required. How you self identify."
              />
            </Grid>
          ) : null}
          <Grid
            item
            md={ethnicity === "Self-Identify" ? 2 : 6}
            xs={ethnicity === "Self-Identify" ? 6 : 12}
          >
            <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="ethnicity-label">Race or Ethnicity</InputLabel>
              <Select
                labelId="ethnicity-label"
                id="ethnicity-select-helper"
                value={ethnicity}
                label="Race or Ethnicity"
                onChange={handleChangeEthnicity}
              >
                {ethnicities.map((g, k) => (
                  <MenuItem value={g} key={k}>
                    {g}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required.</FormHelperText>
            </FormControl>
          </Grid>
          {ethnicity === "Self-Identify" ? (
            <Grid item xs={6} md={4}>
              <TextField
                sx={{ m: 1, width: "100%" }}
                id="ethnicitySelfIdentify"
                label="Self identified Race or Ethnicity"
                value={SIEthnicity}
                onChange={handleChangeSIEthnicity}
                helperText="Required. How you self identify your race or ethnicity."
              />
            </Grid>
          ) : null}

          <Grid item xs={12} md={6}>
            <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="education-label">Education</InputLabel>
              <Select
                labelId="education-label"
                id="education-select-helper"
                value={education}
                label="Education"
                onChange={handleChangeEducation}
              >
                {educations.map((g, k) => (
                  <MenuItem value={g} key={k}>
                    {g}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required.</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="employment-label">Employment</InputLabel>
              <Select
                labelId="employment-label"
                id="employment-select-helper"
                value={employment}
                label="Education"
                onChange={handleChangeEmployment}
              >
                {employments.map((g, k) => (
                  <MenuItem value={g} key={k}>
                    {g}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required.</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="household-comp-label">
                Household Composition
              </InputLabel>
              <Select
                labelId="household-comp-label"
                id="household-comp-select-helper"
                value={householdComp}
                label="Household Composition"
                onChange={handleChangeHouseholdComp}
              >
                {householdComps.map((g, k) => (
                  <MenuItem value={g} key={k}>
                    {g}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required.</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="householde-income-label">
                Household Income
              </InputLabel>
              <Select
                labelId="household-income-label"
                id="household-income-select-helper"
                value={householdIncome}
                label="Household Income"
                onChange={handleChangeHouseholdIncome}
              >
                {householdIncomes.map((g, k) => (
                  <MenuItem value={g} key={k}>
                    {g}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required.</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
              <InputLabel id="industry-label">Industry</InputLabel>
              <Select
                labelId="Industry-label"
                id="Industry-select-helper"
                value={industry}
                label="Industry"
                onChange={handleChangeIndustry}
              >
                {industries.map((g, k) => (
                  <MenuItem value={g} key={k}>
                    {g}
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem value="" key="clear">
                  Clear
                </MenuItem>
              </Select>
              <FormHelperText>Optional.</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <Tooltip title={whySubmitDisabled()}>
              <span>
                <Button
                  className={classes.centeredButton}
                  variant="contained"
                  onClick={onSubmit}
                  disabled={!isSubmitValid()}
                >
                  <Typography sx={AnswerText}>Submit</Typography>
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Registration;
