import React, { useEffect, useState } from "react";

const CountDownTimer = ({ hoursMinSecs }) => {
  const { hours = 0, minutes = 0, seconds = 59 } = hoursMinSecs;
  const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds]);

  const tickRev = () => {
    if (secs === 59 && mins !== 59) {
      setTime([hrs, mins + 1, 0]);
    } else if (secs === 59 && mins === 59) {
      setTime([hrs + 1, 0, 0]);
    } else {
      setTime([hrs, mins, secs + 1]);
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tickRev(), 1000);
    return () => clearInterval(timerId);
  });

  return (
    <div>
      <span
        style={{
          fontSize: "0.75rem",
          color: "#fff",
          fontWeight: "500",
          borderRadius: "0.9375rem",
          paddingInline: "0.75rem",
          paddingBlock: "0.1rem",
          background: "red",
          boxShadow: "0px 4px 24px -1px rgba(0, 0, 0, 0.20)",
        }}
      >{`${hrs.toString().padStart(2, "0")}:${mins
        .toString()
        .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`}</span>
    </div>
  );
};

export default CountDownTimer;
