import transitions from "@material-ui/core/styles/transitions";

export const StudyHeaderText = {
  textAlign: "Left",
  fontSize: {
    xs: 15,
    sm: 18,
    md: 21,
    lg: 25,
    xl: 28,
  },
  fontWeight: 500,
  mb: 1.5,
};

export const HeaderText = {
  fontSize: {
    xs: 20,
    sm: 20,
    md: 25,
    lg: 30,
    xl: 30,
  },
};

export const HeaderText2 = {
  m: 3,
  fontSize: {
    xs: 20,
    sm: 20,
    md: 25,
    lg: 30,
    xl: 30,
  },
};

export const SectionHeaderText = {
  m: 1,
  // color:"#1664C0",
  fontSize: {
    xs: 20,
    sm: 20,
    md: 23,
    lg: 27,
    xl: 27,
  },
  fontWeight: "900",
};

export const QuestionTittleText = {
  fontSize: {
    xs: 15,
    sm: 15,
    md: 18,
    lg: 21,
    xl: 21,
  },
  fontWeight: "600",
  width: "65%",
  flexShrink: 0,
};

export const prescreenLabel = {
  fontSize: {
    xs: 15,
    sm: 15,
    md: 18,
    lg: 21,
    xl: 21,
  },
  fontWeight: "500",
  m: 3,
};

export const infoText = {
  fontSize: "1rem",
  fontWeight: "500",
  m: 1,
  textAlign: "justify",
};

export const QuestionLabels = {
  width: "35%",
  height: "100%",
  fontWeight: "400",
  color: "#BCBCBC",
  fontSize: {
    xs: 12,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 16,
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  paddingRight: "8px",
};

export const QuestionText = {
  fontSize: {
    xs: 15,
    sm: 15,
    md: 20,
    lg: 25,
    xl: 25,
  },
};

export const AnswerText = {
  fontSize: {
    xs: 12,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 16,
  },
};

export const AnswerTextBlue = {
  color: "#3A78F6",
  fontSize: {
    xs: 12,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 16,
  },
};

export const StudyText = {
  fontSize: {
    xs: 12,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 16,
  },
  textAlign: "justify",
};

export const MsgLabelJustify = {
  textAlign: "justify",
  fontSize: {
    xs: 12,
    sm: 12,
    md: 15,
    lg: 20,
    xl: 20,
  },
};

export const Labels = {
  fontSize: {
    xs: 10,
    sm: 10,
    md: 12,
    lg: 13,
    xl: 13,
  },
};

export const studyStartButton = {
  m: 0,
  p: 0,
  mt: 2,
  display: "flex",
  justifyContent: "center",
};

export const NoteText = {
  color: "#000",
  margin: "0px",
  height: "100%",
  fontWeight: "300",
  fontSize: {
    xs: 12,
    sm: 12,
    md: 14,
    lg: 14,
    xl: 16,
  },
};

export const CardContainer = {
  minWidth: 275,
  width: "90vw",
  maxWidth: 500,
  overflowWrap: "anywhere",
  margin: "16px",
  maxHeight: "90vh",
  overflowY: "auto",
};

export const SectionsContainer = {
  minWidth: 275,
  width: "100%",
  maxWidth: {
    xs: "500px !important",
    sm: "500px !important",
    md: "500px !important",
    lg: "500px !important",
    xl: "700px !important",
  },
  overflowWrap: "anywhere",
  margin: "16px",
  alignSelf: "flex-start",
};

export const GridContainer = {
  overflowWrap: "anywhere",
  textAlign: "center",
  padding: "2em",
};

export const modalContainer = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "80%",
    md: "45%",
    lg: "45%",
    xl: "45%",
  },
  boxShadow: 0,
  p: 0,
};

export const marginTop = {
  marginTop: "1.5em",
};

export const marginLeft = {
  marginLeft: "1.5rem",
};

export const marginVertical = {
  marginTop: "1.5em",
  marginBottom: "1.5em",
};

export const column = {
  flexDirection: "column",
};

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "0.375rem",
  boxShadow: 24,
  padding: "2.5rem 1.5rem 1.5rem",
};

export const mainContainerStyle = {
  maxWidth: "600px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
  transition: "0.3s",
  borderRadius: "0.375rem",
  boxShadow: 24,
  padding: "2.5rem 1.5rem 1.5rem",
  margin: "1em",
};

export const containerStyle = {
  maxWidth: "900px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0.375rem",
  padding: "2.5rem 1.5rem 1.5rem",
  margin: 0,
};

export const titleText = {
  color: "#000",
  fontSize: "2.25rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
};

export const subTitleText = {
  color: "#000",
  fontSize: "1.25rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
};

export const surveyHeaderText = {
  color: "#000",
  leadingTrim: "both",
  textEdge: "cap",
  fontSize: "1.25rem",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
};

export const surveySubtitleText = {
  color: "#000",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
};

export const surveyText = {
  color: "#000",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "1.05rem",
  display: "inline-flex",
  alignItems: "center",
};

export const surveyParaText = {
  color: "#000",
  fontSize: "0.8rem",
  fontStyle: "normal",
  lineHeight: "1.5rem",
  textAlign: "justify",
};

export const buttonText = {
  textAlign: "right",
  leadingTrim: "both",
  textEdge: "cap",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
};

export const contentCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const contentStart = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};

export const contentStartColumn = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
};

export const contentStartRow = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
};

export const contentEnd = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
};

export const contentSpaceBetween = {
  display: "flex",
  justifyContent: "space-between",
};

export const iconStyle = {
  fontSize: "1.5rem",
  color: "#3f51b5", // Use blue for the icon
  marginRight: "0.375rem", // Add spacing between the icon and text
};

export const svgStyle = {
  width: "1.5rem",
  height: "1.5rem",
  marginRight: "0.375rem",
};

export const svgSx = {
  width: "1.5rem",
  height: "1.5rem",
};

export const svgSx1 = {
  width: "1rem",
  height: "1rem",
};

export const buttonStyle = {
  textTransform: "none",
  padding: "0.62rem 2rem",
  borderRadius: "0.375rem",
  alignItems: "center",
  "& .MuiButton-startIcon": {
    marginRight: "0.3rem",
  },
};

export const VITextStyle = {
  color: "#000",
  leadingTrim: "both",
  fontSize: "1rem",
  fontWeight: 400,
  textAlign: "justify",
  transition: "ease-in",
};

export const VITitleStyle = {
  color: "#666",
  leadingTrim: "both",
  fontSize: "1rem",
  fontWeight: 400,
  textAlign: "justify",
};
