import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { RadioGroup, Radio, FormControlLabel, Typography } from "@mui/material";
import "antd/dist/antd.css";
import { AnswerText } from "../assets/styles/sxStyles";
import { createEvent } from "../common/util";

const { Option } = Select;

const CustomSelect = (props) => {
  const [answersList, setAnswersList] = useState([]);
  const [value, setValue] = useState([]);

  const onSelectChange = (val) => {
    const textVal = val !== undefined ? val : "";
    setValue(textVal);
    props.onChange(createEvent(props.data.question, textVal, true));
  };

  const onChange = (event) => {
    setValue(event.target.value);
    props.onChange(event);
  };

  useEffect(() => {
    setAnswersList(props.data.answers);
    if (
      props.data.answersOpen &&
      !props.data.answers.includes(props.value) &&
      props.value !== ""
    ) {
      setValue(props.data.answers[props.data.answers.length - 1]);
    } else setValue(props.value);
  }, [setValue, setAnswersList, props]);

  return (
    <>
      {answersList.length > 4 ? (
        <Select
          showSearch={answersList.length > 10}
          mode="tag"
          placeholder="Please select from one of the options"
          optionFilterProp="children"
          disabled={props.disabled}
          name={props.data.question.toString()}
          style={{
            width: "100%",
          }}
          allowClear={true}
          size={"large"}
          onChange={onSelectChange}
          value={value}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          placement="bottomLeft"
        >
          {answersList.map((element, i) => (
            <Option key={i} value={element} className="select-option-text">
              {element}
            </Option>
          ))}
        </Select>
      ) : (
        <RadioGroup
          aria-label={props.data.question.toString()}
          name={props.data.question.toString()}
          value={value}
          width="100%"
        >
          {answersList.map((item, i) => {
            return (
              <FormControlLabel
                value={item}
                control={<Radio />}
                disabled={props.disabled}
                label={<Typography sx={AnswerText}>{item}</Typography>}
                onChange={onChange}
                key={i}
              />
            );
          })}
        </RadioGroup>
      )}
    </>
  );
};

export default CustomSelect;
