import { params } from "./util";
import { getLocalStorage } from "../Models/data/useLocalStorage";

const user = `user-${params.id}`;

export const Keys = {
  User: user,
  Answers: `answers-${params.id}-${getLocalStorage(user).emailHash}`,
  ScreenoutData: `${params.id}-screenoutdata`,
  Screened: `${params.id}-screenedOut`,
  Permission: "avPermission",
  MediaPalyedOnce: `${params.id}-mediaPlayedOnce`,
  DisabledQuestions: `disabledQuest-${params.id}`,
  DisabledSections: `disabledSections-${params.id}`,
  VIOption: `VIOption-${params.id}`

};
