import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  MicNone,
  VideocamOutlined,
  KeyboardOutlined,
} from "@mui/icons-material";
import { capitalizeString } from "../common/util";
import { AnswerText } from "../assets/styles/sxStyles";

const IconText = ({ text }) => {
  const [icon, setIcon] = useState("");

  useEffect(() => {
    switch (text) {
      case "video":
        setIcon(<VideocamOutlined />);
        break;
      case "audio":
        setIcon(<MicNone />);
        break;
      default:
        setIcon(<KeyboardOutlined />);
        break;
    }
  }, [text]);

  return (
    <Grid
      item
      sx={{
        paddingTop: "0.5em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "0.25rem 0.625rem",
        alignItems: "center",
      }}
    >
      {icon}
      &nbsp;
      <Typography sx={AnswerText}>{capitalizeString(text)}</Typography>
    </Grid>
  );
};

export default IconText;
