import React from "react";
import { Button, Typography } from "@mui/material";
import { buttonText } from "../assets/styles/sxStyles";

const CustomButton = ({variant, disabled, handleclick, icon, text, color }) => {
  
  return (
    <Button
      variant={variant}
      startIcon={icon || ""}
      color={color}
      size="small"
      disabled={disabled}
      onClick={handleclick}
      sx={{
        textTransform: "none",
        display: "flex",
        justifyContent: "flex-end",
        padding : "0.62em",
        borderRadius : "0.375rem",
        alignItems: "center",
        "& .MuiButton-startIcon": {
          marginRight: "0.15rem",
        },
      }}
    >
      <Typography sx={buttonText}>{text}</Typography>
    </Button>
  );
};

export default CustomButton;
