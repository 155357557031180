export const Screen_Out_Message =
  "Based on your responses, you don't match the criteria needed for this particular survey. We appreciate your interest in participating.";

export const Optional_Message = "";
//"Payment of $15 in the form of an Amazon gift card is sent to the email you provide within 1-3 days after successfully completing the study.";

export const Closed_Message =
  "Thank you for your interest in our survey. We have closed the study and are no longer accepting responses. We appreciate your participation and value your feedback.";

export const Completed_Message =
  "Your responses have been recorded. We appreciate your time and insights.";

export const OnlyMedia = ["audio", "video", "text", "multi-media", "interview"];

export const Tech_Error_Message =
  "We are experiencing some technical difficulties. Our servers are currently unavailable, but we are working to resolve the issue. Please try again in a few minutes.";

export const Max_TimeOut = 5 * 60 * 1000;

export const problematicPlatforms = [
  "Android Facebook platform",
  "Android Messenger platform",
  "Android Instagram platform",
];

export const interviewQuestion = {
  template: false,
  question: "",
  stimulus: "",
  answersOpen: false,
  questionTotal: 5,
  answers: [],
  answerType: "interview",
  range: false,
  stimulusType: "",
  title: "In-depth App Evaluation",
  env: "deveric",
  dependencies: [],
  promptType: "",
  interviewDataInputHash: "399125e001e495c17c6f4f543ccb69f96a3812a5-",
  isSkippable: false,
};
