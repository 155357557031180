import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "@mui/material";
import { Mic } from "@mui/icons-material";

const AudioVisualizer = ({ isWaveActive, handleClick, isDisabled, type }) => {
  const [waveHeights, setWaveHeights] = useState([2, 2, 2, 2, 2]);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const [buttonBGColor, setButtonBGColor] = useState("transparent");
  const [divBGColor, setDivBGColor] = useState("white");

  useEffect(() => {
    const contentBg =
      type === "audio" ? "transparent" : isWaveActive ? "#3A78F6" : "#B0BEC5";
    setButtonBGColor(contentBg);

    const divBG =
      type === "audio" ? "#DB4848" : isWaveActive ? "white" : "#CFD8DC";
    setDivBGColor(divBG);
  }, [type, isWaveActive]);

  useEffect(() => {
    if (!isWaveActive) return;

    const initializeMicrophone = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();

        audioContextRef.current = audioContext;
        analyserRef.current = analyser;

        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        analyser.fftSize = 256;
        const bufferLength = analyser.frequencyBinCount;
        dataArrayRef.current = new Uint8Array(bufferLength);

        const updateWaves = () => {
          analyser.getByteTimeDomainData(dataArrayRef.current);

          const sensitivityFactor = 5;
          const heights = [
            Math.min(
              16,
              Math.abs((dataArrayRef.current[2] - 128) * sensitivityFactor)
            ),
            Math.min(
              16,
              Math.abs((dataArrayRef.current[6] - 128) * sensitivityFactor)
            ),
            Math.min(
              16,
              Math.abs((dataArrayRef.current[10] - 128) * sensitivityFactor)
            ),
            Math.min(
              16,
              Math.abs((dataArrayRef.current[14] - 128) * sensitivityFactor)
            ),
            Math.min(
              16,
              Math.abs((dataArrayRef.current[16] - 128) * sensitivityFactor)
            ),
          ].map((height) => Math.max(2, height));

          setWaveHeights(heights);
          requestAnimationFrame(updateWaves);
        };

        updateWaves();
      } catch (err) {
        console.error("Microphone access denied:", err);
      }
    };

    initializeMicrophone();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [isWaveActive]);

  return (
    <IconButton
      onClick={handleClick}
      disabled={isDisabled}
      style={{
        position: "relative",
        backgroundColor: buttonBGColor,
        borderRadius: "50%",
        width: "2.5rem",
        height: "2.5rem",
        transition: "background-color 0.4s ease",
        border: type === "audio" ? "1.633px solid #666" : "none",
      }}
    >
      {waveHeights.map((height, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            left: `${(type === "audio" ? 8 : 9) + index * 5}px`,
            bottom: "50%",
            transform: "translateY(50%)",
            width: "2px",
            height: `${isWaveActive ? height : 4}px`,
            backgroundColor: divBGColor,
            borderRadius: "6.25rem",
            transition: "height 0.4s ease, background-color 0.4s ease",
          }}
        />
      ))}
    </IconButton>
  );
};

export default AudioVisualizer;
