import React, { useEffect, useState } from "react";

import { Grid, TextField } from "@mui/material";
import { AnswerTextBlue } from "../assets/styles/sxStyles";

// icons
import { Storage } from "aws-amplify";
import { Progress } from "antd";
import { params } from "../common/util";
import CustomSubmit from "../components/CustomSubmit";

const TextCapture = (props) => {
  const [value, setValue] = useState(props.value);
  const [isDisabled, setIsDisabled] = useState(
    props.value.length > 3 ? false : true
  );

  const [isTesting, setIsTesting] = useState(false);

  // For progress bar
  const [progress, setProgress] = useState(0);
  const [isProgressBar, setIsProgressBar] = useState(false);

  useEffect(() => {
    if (Object.keys(props.userData).length === 0) {
      setIsTesting(true);
    } else {
      setIsTesting(false);
    }
  }, [props.userData]);

  useEffect(() => {
    if (progress === 100) {
      setIsProgressBar(false);
      setProgress(0);
    }
  }, [progress]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value.length > 3) setIsDisabled(false);
    else setIsDisabled(true);
    props.onTextValueChange(event);
  };

  const handleSubmit = async (event) => {
    const userData = props.userData;
    let newFileName = `${params.id}/${props.data.title}-${userData.firstName}${userData.lastInitial}.txt`;

    if (!isTesting) {
      try {
        setIsProgressBar(true);
        const result = await Storage.put(newFileName, value, {
          contentType: "text/plain", // contentType is optional
          progressCallback(progress) {
            setProgress(((progress.loaded / progress.total) * 100).toFixed(0));
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
        newFileName = result.key;
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
    event.target = {
      name: props.data.question,
      value: newFileName,
    };
    props.onChange(event);
  };

  return (
    <Grid container gap={"2.5rem"} marginTop={"2.5rem"}>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          id="outlined-multiline-flexible"
          multiline
          label=""
          inputProps={{ style: AnswerTextBlue }}
          InputLabelProps={{ style: AnswerTextBlue }}
          className="text-area"
          placeholder="Type your answer here..."
          maxRows={5}
          value={value}
          disabled={isProgressBar || props.disabled}
          onChange={handleChange}
          sx={{
            "& .MuiInput-underline:before": {
              borderBottom: "none", // Remove the default underline
            },
            "& .MuiInput-underline:after": {
              borderBottom: "2px solid #3A78F6", // Change the active underline color
            },
            "& .MuiInputBase-input": {
              color: "#3A78F6", // Text color
            },
            "& .MuiInputBase-input::placeholder": {
              color: "#3A78F6", // Placeholder text color
              opacity: 1, // Ensure visibility of placeholder
            },
            "& .Mui-disabled": {
              color: "#3A78F6 !important", // Ensure disabled text is also blue
            },
          }}
        />
      </Grid>

      {value.length > 3 && !props.isSingleSection && (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          {isProgressBar ? (
            <Progress
              type="circle"
              percent={progress}
              width={50}
              strokeWidth={5}
              strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
            />
          ) : (
            <CustomSubmit
              disabled={isProgressBar || props.disabled || isDisabled}
              handleclick={handleSubmit}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default TextCapture;
