import React, { useEffect, useState, useRef, useCallback } from "react";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import lamejs from "lamejs";
import MPEGMode from "lamejs/src/js/MPEGMode";
import Lame from "lamejs/src/js/Lame";
import BitStream from "lamejs/src/js/BitStream";
import hark from "hark";
import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import Switch from "@material-ui/core/Switch";
import {
  NoteText,
  VITextStyle,
  VITitleStyle,
  contentCenter,
} from "../../assets/styles/sxStyles";
import { extractQuestion } from "../../common/util";

import CaptureMedia from "./CaptureMedia";
import AudioVisualizer from "./AudioVisualizer";

window.MPEGMode = MPEGMode;
window.Lame = Lame;
window.BitStream = BitStream;

function RecordMedia({ onStart, onStop, isEnabled, isLoading, data, mode }) {
  const audioRecorder = useRef(null);
  const videoRecorder = useRef(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const videoRef = useRef(null);
  const listener = useRef(null);
  const speakerSilenceTimer = useRef(null);
  const speaking = useRef(false);
  const isSpeaking = useRef(false);
  const muteMic = useRef(true);
  const chunks = useRef([]);
  const audioContext = useRef(null);
  const destination = useRef(null);
  const sendInProgress = useRef(false);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [autoSendOnSilence, setAutoSendOnSilence] = useState(false);
  const autoSendOnSilenceRef = useRef(false);
  const [displayedText, setDisplayedText] = useState("");
  const [stream, setStream] = useState(null);
  const [isWaveActive, setIsWaveActive] = useState(false);

  useEffect(() => {
    setIsWaveActive(false);
    const initialTime = data?.text.length * 60;

    if (initialTime > 0) {
      const interval = setInterval(() => {
        setIsWaveActive(true);
        return () => clearInterval(interval);
      }, initialTime);
    }
  }, [data.text, setIsWaveActive]);

  useEffect(() => {
    let fullText = extractQuestion(data?.text);
    setDisplayedText("");
    let index = 0;
    // const initialTime = data?.text.length * 60;
    const initialTime = data?.text.length * 60 - fullText.length * 50;

    const initialTimeout = setTimeout(() => {
      const interval = setInterval(() => {
        if (index < fullText.length) {
          setDisplayedText((prev) => prev + fullText[index]);
          index++;
        } else clearInterval(interval);
      }, 40);
      return () => clearInterval(interval);
    }, initialTime);

    return () => clearTimeout(initialTimeout);
  }, [data.text]);

  useEffect(() => {
    autoSendOnSilenceRef.current = autoSendOnSilence;
  }, [autoSendOnSilence]);

  const onStartSpeaking = () => {
    if (speakerSilenceTimer.current) clearTimeout(speakerSilenceTimer.current);
    speakerSilenceTimer.current = undefined;
    console.log("speaking");
    speaking.current = true;
    if (!isSpeaking.current) {
      isSpeaking.current = true;
      console.log("SPEAKING");
    }
  };

  const onStopSpeaking = () => {
    speakerSilenceTimer.current = setTimeout(() => {
      if (!sendInProgress.current) {
        console.log("STOP_SPEAKING");
        isSpeaking.current = false;
        console.log("autoSendOnSilence", autoSendOnSilenceRef.current);
        if (autoSendOnSilenceRef.current) stopRecording();
      }
      sendInProgress.current = false;
    }, 3000);
    console.log("stopped_speaking");
    speaking.current = false;
  };

  const blobToArrayBuffer = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  };

  const computeRMS = (audioBuffer) => {
    let squares = 0;
    const data = new Int16Array(audioBuffer);
    for (let i = 0; i < data.length; i++) {
      squares += data[i] * data[i];
    }
    const meanSquare = squares / data.length;
    const rms = Math.sqrt(meanSquare);
    return rms;
  };

  const handleDataAvailable = async (blob) => {
    convertToMp3(blob)
      .then(async (mp3Data) => {
        chunks.current.push(mp3Data);
        if (!isSpeaking.current && chunks.current.length > 2)
          chunks.current.shift();
        if (chunks.current.length > 4) {
          setSendDisabled(false);
          console.log("DEBUG");
        } else setSendDisabled(true);
      })
      .catch((error) => {
        console.error("Error converting to MP3:", error);
      });
  };

  useEffect(() => {
    if (stream) {
      audioContext.current = new AudioContext();
      audioContext.current.resume();
      destination.current = audioContext.current.createMediaStreamDestination();
      const micSource = audioContext.current.createMediaStreamSource(stream);
      micSource.connect(destination.current);

      const videoTrack = stream.getVideoTracks()[0];
      const mixedAudioTrack = destination.current.stream.getAudioTracks()[0];
      const recordingStream = new MediaStream([videoTrack, mixedAudioTrack]);

      const audioRecorderInstance = RecordRTC(stream, {
        type: "audio",
        mimeType: "audio/wav",
        numberOfAudioChannels: 1,
        recorderType: StereoAudioRecorder,
        sampleRate: 44100,
        timeSlice: 1000,
        ondataavailable: handleDataAvailable,
      });
      const videoRecorderInstance = RecordRTC(recordingStream, {
        type: "video",
        mimeType: "video/mp4",
      });
      audioRecorder.current = audioRecorderInstance;
      videoRecorder.current = videoRecorderInstance;
      audioRecorderInstance.startRecording();
      videoRecorderInstance.startRecording();

      listener.current = hark(stream, { interval: 100, play: false });
      listener.current.on("speaking", onStartSpeaking);
      listener.current.on("stopped_speaking", onStopSpeaking);
      if (videoRef.current) videoRef.current.srcObject = stream;

      onStop(
        null,
        audioContext.current,
        destination.current,
        muteMic.current,
        videoRecorder.current
      );
    }
  }, [stream]);

  useEffect(() => {
    return () => {
      if (listener.current) {
        listener.current.off("speaking", onStartSpeaking);
        listener.current.off("stopped_speaking", onStopSpeaking);
        listener.current = undefined;
      }
      if (audioRecorder.current) {
        audioRecorder.current.destroy();
        audioRecorder.current = null;
      }
      if (videoRecorder.current) {
        videoRecorder.current.destroy();
        videoRecorder.current = null;
      }
    };
  }, []);

  const send = async () => {
    setSendDisabled(true);
    if (speakerSilenceTimer.current) {
      clearTimeout(speakerSilenceTimer.current);
      speakerSilenceTimer.current = undefined;
    }

    if (chunks.current.length > 4) {
      sendInProgress.current = true;
      isSpeaking.current = false;
      stopRecording();
      speaking.current = false;
    }
  };

  const stopRecording = async () => {
    console.log("stopRecording");
    muteMic.current = true;
    onStart();
    const stopPromises = [
      new Promise((resolve) => audioRecorder.current.stopRecording(resolve)),
    ];
    await Promise.all(stopPromises);

    const audioBlob = new Blob(chunks.current, { type: "audio/mp3" });
    audioRecorder.current.reset();
    console.log("chunks", chunks.current, audioBlob);
    chunks.current = [];
    audioRecorder.current.startRecording();
    onStop(
      audioBlob,
      audioContext.current,
      destination.current,
      muteMic.current,
      videoRecorder.current
    );
  };

  const convertToMp3 = (audioBlob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        try {
          const wavData = new Int8Array(event.target.result);
          const mp3Data = encodeMp3(wavData);
          resolve(mp3Data);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsArrayBuffer(audioBlob);
    });
  };

  const encodeMp3 = (wavData) => {
    const wav = lamejs.WavHeader.readHeader(new DataView(wavData.buffer));
    const mp3encoder = new lamejs.Mp3Encoder(wav.channels, wav.sampleRate, 96);
    const mp3Data = [];
    const samples = new Int16Array(
      wavData.buffer,
      wavData.byteOffset,
      wavData.byteLength / 2
    );
    const mp3buf = mp3encoder.encodeBuffer(samples);
    if (mp3buf.length > 0) {
      mp3Data.push(new Int8Array(mp3buf));
    }
    const mp3bufEnd = mp3encoder.flush();
    if (mp3bufEnd.length > 0) {
      mp3Data.push(new Int8Array(mp3bufEnd));
    }
    return new Blob(mp3Data, { type: "audio/mp3" });
  };

  return (
    <Grid container gap={"2.5rem"}>
      <div style={{ display: "none" }}>
        <CaptureMedia onStream={setStream} mode={mode} />
      </div>

      <Grid item container xs={12}>
        <Grid item xs={12} mb={"1.25rem"}>
          <Typography sx={VITitleStyle}>VIRTUAL INTERVIEW</Typography>
        </Grid>

        <Grid item xs={12} sx={contentCenter}>
          {displayedText.length > 0 ? (
            <Typography
              style={{ whiteSpace: "pre-wrap" }}
              sx={{
                ...VITextStyle,
                minHeight: `calc(1em * 3 * 1.5)`,
                lineHeight: 1.5,
                overflow: "hidden",
              }}
            >
              {displayedText.split(" ").map((word, index) => (
                <span
                  key={index}
                  style={{
                    opacity: 0,
                    display: "inline-block",
                    animation: `fadeIn 0.5s ease forwards`,
                    animationDelay: `${index * 0.1}s`,
                    marginRight: "0.25rem",
                  }}
                >
                  {word}
                </span>
              ))}
            </Typography>
          ) : (
            <Box sx={{ width: "100%" }}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" width={"50%"} />
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} sx={contentCenter}>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          style={{
            transform: "scaleX(-1)",
            maxWidth: "100%",
            height: mode === "video" ? "auto" : 0,
          }}
        />
      </Grid>

      <Grid item container xs={12} sx={contentCenter}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {isEnabled && (
              <AudioVisualizer
                handleClick={send}
                isWaveActive={isWaveActive}
                isdisabled={sendDisabled}
                type="interview"
              />
            )}

            {!sendDisabled && isWaveActive && (
              <Grid item xs={12} mt={"0.75rem"} sx={contentCenter}>
                <Typography sx={NoteText}>Tap when done speaking.</Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>

      {false && (
        <Switch
          checked={autoSendOnSilence}
          onChange={(event) => {
            setAutoSendOnSilence(event.target.checked);
          }}
          value="autoSendOnSilence"
        />
      )}

      {videoUrl && (
        <Grid item xs={12} sx={contentCenter}>
          <video src={videoUrl} controls />
        </Grid>
      )}
    </Grid>
  );
}

export default RecordMedia;
