import React, { useState, useEffect, useRef, useCallback } from "react";

import { Grid, Button, IconButton, Typography } from "@mui/material";

import Webcam from "react-webcam";
import ReactPlayer from "react-player/lazy";

// for icons
import FlipCameraAndroidRoundedIcon from "@mui/icons-material/FlipCameraAndroidRounded";

// Amplify upload
import { Storage } from "aws-amplify";

// css
import { AnswerText, NoteText } from "../assets/styles/sxStyles";
import "../App.css";
import "antd/dist/antd.css";
import { Progress } from "antd";
import { params } from "../common/util";
import { Circle, SendRounded, SquareRounded } from "@mui/icons-material";
import CountDownTimer from "../components/CountDownTimer";
import CustomSubmit from "../components/CustomSubmit";

const contentCenter = {
  display: "flex",
  justifyContent: "center",
};

const VideoCapture = (props) => {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [isTesting, setIsTesting] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  // For progress bar
  const [progress, setProgress] = useState(0);
  const [isProgressBar, setIsProgressBar] = useState(false);
  const [isFrontFaceOn, setFrontFaceOn] = useState(true);
  const [videoConstraints, setVideoConstraints] = useState(null);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isAppleDevice = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);

  // timer for recording audio
  const hoursMinSecs = { hours: 0, minutes: 0, seconds: 0 };

  useEffect(() => {
    if (isAppleDevice)
      setVideoConstraints({
        facingMode: isFrontFaceOn ? "user" : "environment",
        video: {
          mimeType: "video/mp4",
          audioBitsPerSecond: 190000,
          videoBitsPerSecond: 1000000,
        },
      });
    else
      setVideoConstraints({
        facingMode: isFrontFaceOn ? "user" : "environment",
        video: {
          mimeType: "video/mp4",
        },
      });
  }, [isFrontFaceOn]);

  useEffect(() => {
    if (props.userData === undefined) setIsTesting(true);
    else setIsTesting(false);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setIsProgressBar(false);
      setProgress(0);
    }
  }, [progress]);

  useEffect(() => {
    if (props.isPromptMediaPlaying) setIsPlaying(false);
  }, [props.isPromptMediaPlaying]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        if (props.nextEnabled) props.onHandlingData(data);
        setRecordedChunks((prev) => prev.concat(data));

        const video = new Blob([data], {
          type: "video/mp4",
        });
        const url = window.URL.createObjectURL(video);
        setVideoFilePath(url);
        console.log(url);
      }
      props.onRecording(false);
    },
    [setRecordedChunks, setVideoFilePath]
  );

  const onStartPlaying = () => {
    setIsPlaying(true);
    props.onPlaying(true);
  };

  const onStopPlaying = () => {
    setIsPlaying(false);
    props.onPlaying(false);
  };

  const handleStartCaptureClick = useCallback(() => {
    props.onRecording(true);
    setCapturing(true);

    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream);

    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    props.onRecording(false);
    setCapturing(false);
    mediaRecorderRef.current.stop();
  }, [mediaRecorderRef, setCapturing]);

  const handleSubmit = useCallback(
    async (event) => {
      props.onRecording(false);
      const userData = props.userData;
      let newFileName = `${params.id}/${props.data.title}-${userData.firstName}${userData.lastInitial}.mp4`;

      const file = {
        // `uri` can also be a file system path (i.e. file://)
        uri: new File(recordedChunks, newFileName),
        name: newFileName,
        type: "video/mp4",
      };

      if (!isTesting) {
        try {
          setIsProgressBar(true);
          const result = await Storage.put(file.name, file.uri, {
            contentType: "video/mp4", // contentType is optional
            progressCallback(progress) {
              setProgress(
                ((progress.loaded / progress.total) * 100).toFixed(0)
              );
            },
            errorCallback: (err) => {
              console.error("Unexpected error while uploading", err);
            },
          });
          newFileName = result.key;
          setRecordedChunks([]);
        } catch (error) {
          console.error("Error uploading file: ", error);
        }
      } else setRecordedChunks([]);

      event.target = {
        name: props.data.question,
        value: newFileName,
      };
      props.onChange(event);
    },
    [recordedChunks, isTesting]
  );

  return (
    <Grid container className="answer-container">
      {recordedChunks.length > 0 ? (
        <Grid container gap={"1.5rem"}>
          <Grid item xs={12} className="prompt-video-container">
            <ReactPlayer
              controls
              playsinline={true}
              playing={isPlaying}
              onPlay={onStartPlaying}
              onPause={onStopPlaying}
              onEnded={onStopPlaying}
              width={
                (!isPlaying || !props.disabled) && !props.isPromptMediaPlaying
                  ? "100%"
                  : "60%"
              }
              height={
                (isPlaying || !props.disabled) && !props.isPromptMediaPlaying
                  ? "100%"
                  : "60%"
              }
              className="video"
              url={videoFilePath}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "1rem",
            }}
          >
            <Grid item xs="auto">
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setRecordedChunks([]);
                  props.onRecording(false);
                  if (props.nextEnabled) props.onHandlingData(null);
                }}
                disabled={isProgressBar}
                sx={{
                  color: "#666",
                  borderColor: "#666",
                  textTransform: "none",
                  fontSize: "0.875rem",
                }}
              >
                <Typography sx={AnswerText}>Re-Record</Typography>
              </Button>
            </Grid>

            <Grid item xs="auto">
              {isProgressBar ? (
                <Progress
                  type="circle"
                  percent={progress}
                  width={50}
                  strokeWidth={5}
                  strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                />
              ) : (
                <Grid item xs="auto">
                  {!props.nextEnabled && !props.isSingleSection && (
                    <CustomSubmit
                      disabled={isProgressBar}
                      handleclick={handleSubmit}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* <Grid item xs={12} alignSelf={"center"}>
            <Typography sx={NoteText}>
              Note: Click on{" "}
              {!props.isLast || !props.isSingleSection ? "submit" : "next"} to
              upload the recording
            </Typography>
          </Grid> */}
        </Grid>
      ) : (
        <Grid container gap={"1rem"}>
          <Grid item xs={12} className="prompt-video-container">
            <Grid
              container
              sx={{
                margin: 0,
                padding: 0,
                position: "relative",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  width: "inherit",
                  display: "flex",
                  padding: "0 !important",
                  justifyContent: "flex-start",
                  margin: "0 !important",
                  height: 0,
                  position: "absolute",
                  top: "0.15rem",
                  left: "0.375rem",
                  zIndex: 1,
                }}
              >
                {capturing ? (
                  <CountDownTimer hoursMinSecs={hoursMinSecs} />
                ) : (
                  <div>
                    <span
                      style={{
                        fontSize: "0.75rem",
                        color: "#fff",
                        fontWeight: "500",
                        borderRadius: "0.9375rem",
                        paddingInline: "0.75rem",
                        paddingBlock: "0.1rem",
                        boxShadow: "0px 4px 24px -1px rgba(0, 0, 0, 0.20)",
                      }}
                    >
                      00:00:00
                    </span>
                  </div>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  padding: "0 !important",
                  margin: "0 !important",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Webcam
                  audio={true}
                  muted={true}
                  ref={webcamRef}
                  width={!props.disabled ? "100%" : "60%"}
                  className="video"
                  videoConstraints={videoConstraints}
                />
              </Grid>
            </Grid>

            {isMobile && (
              <Grid
                item
                xs={12}
                style={{
                  fontSize: "0.75rem",
                  fontWeight: "500",
                  borderRadius: "0.9375rem",
                  boxShadow: "0px 4px 24px -1px rgba(0, 0, 0, 0.20)",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "-2.5rem",
                }}
              >
                <IconButton
                  aria-label="reverse cam"
                  onClick={() => {
                    setFrontFaceOn(!isFrontFaceOn);
                  }}
                >
                  <FlipCameraAndroidRoundedIcon
                    style={{ color: "white" }}
                    fontSize="1.5em"
                  />
                </IconButton>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} marginTop={"1.5rem"} sx={contentCenter}>
            {capturing ? (
              <IconButton
                sx={{
                  border: "2px #666666 solid",
                  width: "40px",
                  height: "40px",
                }}
                onClick={handleStopCaptureClick}
              >
                <SquareRounded sx={{ fontSize: "21px", color: "#DB4848" }} />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  border: "2px #666666 solid",
                  width: "40px",
                  height: "40px",
                }}
                onClick={handleStartCaptureClick}
                disabled={props.disabled}
              >
                <Circle sx={{ fontSize: "33px", color: "#DB4848" }} />
              </IconButton>
            )}
          </Grid>

          <Grid item xs={12} sx={contentCenter}>
            <Typography sx={NoteText}>
              {!capturing
                ? "Tap to start recording."
                : "Tap when done speaking."}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default VideoCapture;
