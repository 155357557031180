import { useEffect, useRef } from "react";
import AudioVisualizer from "./AudioVisualizer";
import { Grid, IconButton } from "@mui/material";
import AudioVisualizer2 from "./AudioVisualizer2";

function CaptureMedia({ onStream, mode }) {
  const stream = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    async function getMedia() {
      if (mode === "video") {
        try {
          stream.current = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true,
          });
          onStream(stream.current);
          console.error("CaptureMedia", stream.current);
        } catch (e) {
          console.error("Media capture error:", e);
        }
      } else {
        try {
          const streamAudio = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });
          const audioTrack = streamAudio.getAudioTracks()[0];

          //const ctx = canvas.getContext('2d');
          // Draw the static image on the canvas
          canvasRef.current.width = 480;
          canvasRef.current.height = 360;

          // Create a stream from the canvas
          const canvasStream = canvasRef.current.captureStream(25); // 25 FPS
          stream.current = new MediaStream([
            audioTrack,
            ...canvasStream.getVideoTracks(),
          ]);
          onStream(stream.current);
          console.error("CaptureMedia", stream.current);
        } catch (e) {
          console.error("Media capture error:", e);
        }
      }
    }

    getMedia();
    return () => {
      console.log("dddd");
      if (stream.current) {
        stream.current.getTracks().forEach((track) => {
          console.log("track.readyState", track.readyState);
          if (track.readyState == "live") {
            track.stop();
          }
        });
        stream.current = null;
      }
    };
  }, [onStream]);

  return (
    <Grid container height={0} width={0}>
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      {mode !== "video" && stream.current && (
        <AudioVisualizer2 canvasRef={canvasRef} stream={stream.current} />
      )}
    </Grid>
  );
}

export default CaptureMedia;
