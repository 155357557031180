import React, { useEffect, useState, useCallback } from "react";
import { AnswerText } from "../assets/styles/sxStyles";

import { Grid, TextField, Button, Typography } from "@mui/material";

import { KeyboardDoubleArrowRightOutlined } from "@mui/icons-material";

import CustomSubmit from "./CustomSubmit";

import VideoCapture from "../Models/VideoCapture";
import CustomMultiSelect from "./CustomMultiSelect";
import CustomRating from "./CustomRating";
import CustomSelect from "./CustomSelect";
import AudioCapture from "../Models/AudioCapture";
import TextCapture from "../Models/TextCapture";
import {
  getLocalStorage,
  setLocalStorage,
} from "../Models/data/useLocalStorage";
import StimulusModal from "./stimulusModal";
import { capitalizeString, checkIfAnswered, params } from "../common/util";
import { Keys } from "../common/keys";
import { Segmented } from "antd";
import IconText from "./IconText";
import VideoChat from "./VirtualInterviewer/VideoChat";

const Answers = (props) => {
  const [question] = useState(props.questionData);

  const [isVideo, setIsVideo] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [isText, setIsText] = useState(false);
  const [isRadio, setIsRadio] = useState(false);
  const [isCheckbox, setIsCheckbox] = useState(false);
  const [isRating, setIsRating] = useState(false);
  const [isRankedSelect, setIsRankedSelect] = useState(false);
  const [isMultiMedia, setIsMultiMedia] = useState(false);
  const [multiMediaValue, setMultiMediaValue] = useState("");

  //for interview
  const [isInterview, setIsInterview] = useState(false);
  const [interviewIndex, setInterviewIndex] = useState(0);
  const [interviewInProgress, setInterviewInProgress] = useState(false);

  const [isAnswerDisabled, setIsAnswerDisabled] = useState(props.isAnsDisabled);

  const [multimediaOptions, setMultimediaOptions] = useState([]);

  const [value, setValue] = useState("");
  const [optionText, setOptionText] = useState("");
  const [textValue, setTextValue] = useState("");

  const [isLastSelectedAnswerOpen, setIsLastSelectedAnswerOpen] =
    useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [isAutoPlay, setIsAutoPlay] = useState(false);
  const [isAnswered] = useState(
    checkIfAnswered(props.userData.emailHash, props.index)
  );

  const [isAnsPlaying, setIsAnsPlaying] = useState(false);
  const [isSkippable] = useState(props.questionData.isSkippable || false);

  const [permissions] = useState(getLocalStorage(Keys.Permission));

  // A reset function
  const resetStates = () => {
    setIsCheckbox(false);
    setIsAudio(false);
    setIsRadio(false);
    setIsText(false);
    setIsVideo(false);
    setIsRating(false);
    setIsRankedSelect(false);
    setIsInterview(false);
  };

  // State mapping function
  const stateMapping = {
    video: () => setIsVideo(true),
    audio: () => setIsAudio(true),
    text: () => setIsText(true),
    "single-select": () => setIsRadio(true),
    "multi-select": () => setIsCheckbox(true),
    rating: () => setIsRating(true),
    "ranked-select": () => {
      setIsRankedSelect(true);
      setIsCheckbox(true);
    },
    "multi-media": () => setIsMultiMedia(true),
    interview: () => setIsInterview(true),
  };

  // Function to set states based on value
  const updateState = (val) => {
    resetStates();
    stateMapping[val.toLowerCase()]?.();
  };

  const isSubmitInProgress = (e) => setInterviewInProgress(e);

  const finishInterview = (interviewAnswers) => {
    console.log("finishInterview", interviewAnswers);
    const viOption = getLocalStorage(Keys.VIOption);
    setLocalStorage(Keys.VIOption, {
      index: viOption.index + 1,
      accept: true,
      section: viOption.section,
    });
    setValue(interviewAnswers);
    nextQuestion(interviewAnswers);
  };

  useEffect(() => {
    setIsAnswerDisabled(props.isAnsDisabled);
    setIsAutoPlay(false);

    if (!isAnswered && props.questionData.promptType === "text")
      if (["video", "audio"].includes(props.questionData.stimulusType))
        setIsAutoPlay(true);
  }, [
    props.isMediaPlaying,
    setIsAnswerDisabled,
    isAnswered,
    props.isAnsDisabled,
    props.questionData,
  ]);

  useEffect(() => {
    const storedAns = getLocalStorage(
      `answers-${params.id}-${props.userData.emailHash}`
    );
    setIsLastSelectedAnswerOpen(false);

    if (storedAns !== null) {
      const ansIndex = storedAns.findIndex((item) => item.id === props.index);
      if (storedAns[ansIndex]?.answer !== undefined) {
        setValue(storedAns[ansIndex].answer);
        setTextValue(storedAns[ansIndex].textVal);

        if (props.questionData.answersOpen) {
          if (props.questionData.answerType === "single-Select") {
            const check = props.questionData.answers.includes(
              storedAns[ansIndex].answer
            );
            if (!check) {
              setIsLastSelectedAnswerOpen(true);
              setOptionText(storedAns[ansIndex].answer);
            }
          } else {
            const answerOptionsList = props.questionData.answers;
            let answersData = [];

            answersData = storedAns[ansIndex].answer.split(";");
            let uncheckedData = [];
            let checkedData = [];
            answersData.forEach((element) => {
              if (!answerOptionsList.includes(element))
                uncheckedData.push(element);
              else checkedData.push(element);
            });

            if (uncheckedData.length > 0 && uncheckedData[0] !== "") {
              checkedData.push(answerOptionsList[answerOptionsList.length - 1]);
              setIsLastSelectedAnswerOpen(true);
              setOptionText(uncheckedData.join(","));
            }
          }
        }
      }
    }
  }, [props]);

  useEffect(() => {
    if (question.answerType) updateState(question.answerType);
  }, [question]);

  useEffect(() => {
    if (isMultiMedia) {
      const priority = { video: 1, audio: 2, text: 3 };

      const medialist = question.answers
        .filter((element) => {
          if (element === "video")
            return permissions.video && permissions.audio;
          if (element === "audio") return permissions.audio;
          return element !== false;
        })
        .map((item) => ({
          value: item,
          label: <IconText text={item} />,
        }))
        .sort((a, b) => (priority[a.value] || 4) - (priority[b.value] || 4)); // Default priority for unrecognized types is 4

      setMultimediaOptions(medialist);

      const firstOption = medialist[0]?.value;
      setMultiMediaValue(firstOption);

      setIsVideo(firstOption === "video");
      setIsAudio(firstOption === "audio");
      setIsText(firstOption === "text");
    }
  }, [isMultiMedia]);

  const nextQuestion = useCallback(
    (file) => {
      console.log(file);

      const valueString = file;
      const storageKey = `answers-${params.id}-${props.userData.emailHash}`;
      const answerData = getLocalStorage(storageKey) || [];
      const newAnswer = {
        question: question.title,
        answer: valueString,
        textVal: isText ? textValue : "",
        id: props.index,
        type: question.answerType,
      };

      const existingAnswerIndex = answerData.findIndex(
        (el) => el.question === question.title
      );

      existingAnswerIndex !== -1
        ? (answerData[existingAnswerIndex] = newAnswer)
        : answerData.push(newAnswer);

      setLocalStorage(storageKey, answerData);
      props.onChange(props.index + 1);
    },
    [textValue]
  );

  const handleSelect = (e) => {
    setValue(e.target.value);
    const lastAnsIndex = props.questionData.answers.length - 1;
    if (isCheckbox) {
      let checkedArray = e.target.value.split(";");
      if (props.questionData.answersOpen) {
        if (checkedArray.includes(props.questionData.answers[lastAnsIndex]))
          setIsLastSelectedAnswerOpen(true);
        else {
          setIsLastSelectedAnswerOpen(false);
          setOptionText("");
        }
      }
    } else {
      if (e.target.value === "") return;
      if (props.questionData.answersOpen) {
        if (props.questionData.answers[lastAnsIndex] === e.target.value)
          setIsLastSelectedAnswerOpen(true);
        else {
          setIsLastSelectedAnswerOpen(false);
          setOptionText("");
          nextQuestion(e.target.value);
        }
      } else nextQuestion(e.target.value);
    }
  };

  const handleSkip = () => nextQuestion("");

  const handleOptionSubmit = () => {
    let selectValue = "";
    const lastAnsIndex = question.answers.length - 1;
    if (isCheckbox)
      selectValue = (
        question.answersOpen
          ? [
              ...value
                .split(";")
                .filter(
                  (item) =>
                    item !== question.answers[lastAnsIndex] &&
                    question.answers.includes(item)
                ),
              optionText,
            ]
          : value.split(";")
      )
        .filter((element) => element !== "")
        .join(";");
    else selectValue = optionText;

    nextQuestion(selectValue);
  };

  const setMediaRecord = (e) => {
    setIsRecording(e);
    props.onMediaRecording(e);
  };

  const setMediaPlaying = (e) => {
    setIsAnsPlaying(e);
    props.onAnsMediaPlaying(e);
  };

  const handleChange = (e) => nextQuestion(e.target.value);

  const setAnswerOption = (val) => updateState(val);

  return (
    <Grid container gap={1}>
      {question.stimulusType && (
        <Grid item xs={12}>
          <StimulusModal
            type={capitalizeString(question.stimulusType)}
            url={question.stimulus}
            allow={props.isMediaPlaying || isRecording || isAnsPlaying}
            autoPlay={isAutoPlay}
          />
        </Grid>
      )}

      {isMultiMedia && multimediaOptions.length > 1 && (
        <Grid item xs={12} paddingTop={"2.5em"} paddingBottom={"1.5em"}>
          <Segmented
            className="custom-segmented"
            options={multimediaOptions}
            value={multiMediaValue}
            disabled={isAnswerDisabled}
            onChange={(e) => {
              console.log(e);
              setMultiMediaValue(e);
              setAnswerOption(e);
            }}
            size="large"
            height={"100%"}
            block
          />
        </Grid>
      )}

      {isVideo && (
        <VideoCapture
          disabled={isAnswerDisabled}
          onChange={handleChange}
          data={question}
          isSingleSection={false}
          isPromptMediaPlaying={props.isMediaPlaying}
          userData={props.userData}
          onRecording={setMediaRecord}
          onPlaying={setMediaPlaying}
          nextEnabled={false}
        />
      )}

      {isAudio && (
        <AudioCapture
          disabled={isAnswerDisabled}
          onChange={handleChange}
          data={question}
          isSingleSection={false}
          isPromptMediaPlaying={props.isMediaPlaying}
          onRecording={setMediaRecord}
          onPlaying={setMediaPlaying}
          userData={props.userData}
          nextEnabled={false}
        />
      )}

      {isText && (
        <TextCapture
          disabled={isAnswerDisabled}
          onChange={handleChange}
          data={question}
          value={textValue}
          onTextValueChange={(e) => setTextValue(e.target.value)}
          userData={props.userData}
          isSingleSection={false}
        />
      )}

      {isRadio && (
        <Grid item xs={12} gap={"2em"}>
          <CustomSelect
            onChange={handleSelect}
            data={question}
            value={value}
            disabled={isAnswerDisabled}
          />
          {isLastSelectedAnswerOpen && (
            <Grid container paddingY={2} gap={2}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label=" "
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                  size="small"
                  name="optionText"
                  value={optionText}
                  disabled={isAnswerDisabled}
                  placeholder="Please enter your option"
                  onChange={(e) => setOptionText(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSubmit
                  disabled={optionText.length > 3 ? false : true}
                  handleclick={handleOptionSubmit}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      {isRating && (
        <Grid item xs={12}>
          <CustomRating
            onChange={handleChange}
            data={question}
            value={value}
            disabled={isAnswerDisabled}
          />
        </Grid>
      )}

      {isInterview && (
        <Grid item xs={12}>
          <VideoChat
            id={params.id}
            panelist_={`${props.userData.firstName}${props.userData.lastInitial}`}
            firstName_={props.userData.firstName}
            interviewTitle={question.title}
            onFinish={finishInterview}
            onInterviewIndex={setInterviewIndex}
            isLastQuestion={false}
            questionCount={question.questionTotal}
            submitInProgress={isSubmitInProgress}
          />
        </Grid>
      )}

      {isCheckbox && (
        <Grid container gap={1}>
          <Grid item xs={12}>
            <CustomMultiSelect
              onChange={handleSelect}
              data={question}
              value={value}
              isRankedSelect={isRankedSelect}
              disabled={isAnswerDisabled}
            />

            {isLastSelectedAnswerOpen && (
              <Grid container paddingY={1} gap={1}>
                <Grid item xs={12}>
                  <Typography variant="caption" display="block" gutterBottom>
                    For multiple answer seperate the words by comma ,
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    label=" "
                    fullWidth
                    InputLabelProps={{ shrink: false }}
                    size="small"
                    name="optionText"
                    value={optionText}
                    disabled={isAnswerDisabled}
                    placeholder="Please enter your option"
                    onChange={(e) => setOptionText(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            marginY={1}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CustomSubmit handleclick={handleOptionSubmit} disabled={false} />
          </Grid>
        </Grid>
      )}

      {isSkippable && (
        <Grid item xs={12} paddingY={2} gap={2}>
          <Button
            variant="outlined"
            endIcon={
              <KeyboardDoubleArrowRightOutlined style={{ fontSize: 20 }} />
            }
            size="small"
            onClick={handleSkip}
          >
            <Typography sx={AnswerText}>Skip</Typography>{" "}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Answers;
