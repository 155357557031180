import { getLocalStorage } from "../Models/data/useLocalStorage";
import {
  requestBothPermission,
  requestCameraPermission,
  requestMicrophonePermission,
} from "../services/Permissions";

export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const checkIfAnswered = (emailHash, index) => {
  const storedAns = getLocalStorage(`answers-${params.id}-${emailHash}`);
  return storedAns.some((item) => item.index === index);
};

export const createEvent = (question, value, checked) => {
  return {
    target: {
      name: question,
      value: value,
      checked: checked,
    },
  };
};

export const getSocialMediaPlatform = (userAgent) => {
  if (userAgent.includes("Facebook")) {
    // Facebook platform detected
    return "Facebook platform";
  } else if (userAgent.includes("Twitter")) {
    // Twitter platform detected
    return "Twitter platform";
  } else if (userAgent.includes("Instagram")) {
    // Instagram platform detected
    if (userAgent.includes("Android")) {
      return "Android Instagram platform";
    } else {
      return "Instagram platform";
    }
  } else if (userAgent.includes("LinkedIn")) {
    // LinkedIn platform detected
    return "LinkedIn platform";
  } else if (userAgent.includes("FBAN")) {
    // LinkedIn platform detected
    return "Messenger platform";
  } else if (userAgent.includes("FB_IAB")) {
    // LinkedIn platform detected
    if (userAgent.includes("Orca-Android")) {
      return "Android Messenger platform";
    } else {
      return "Android Facebook platform";
    }
  }
};

export const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export const OnlyMedia = ["audio", "video", "text", "multi-media", "interview"];

export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /mobile|android|iphone|ipad|phone|tablet/i.test(userAgent);

  if (/edg/i.test(userAgent)) {
    return "Microsoft_Edge";
  } else if (/opera|opr/i.test(userAgent)) {
    return "Opera";
  } else if (/chrome|crios|chromium/i.test(userAgent)) {
    return isMobile ? "Chrome_Mobile" : "Chrome";
  } else if (/firefox|fxios/i.test(userAgent)) {
    return isMobile ? "Firefox_Mobile" : "Firefox";
  } else if (
    /safari/i.test(userAgent) &&
    !/chrome|crios|chromium/i.test(userAgent)
  ) {
    return isMobile ? "Safari_Mobile" : "Safari";
  } else if (isMobile) {
    return "Other_Mobile_Browser";
  } else {
    return "Unknown";
  }
};

export const detectMob = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: systemTimeZone,
  };

  let formattedDate = date.toLocaleString("en-US", dateOptions);

  if (date.getHours() === 0 && date.getMinutes() === 0) {
    formattedDate = formattedDate.replace(
      /, \d{1,2}:\d{2} (AM|PM)/,
      ", at midnight"
    );
  }
  return `${formattedDate} (${systemTimeZone})`;
};

export const getTimeToComplete = (questions) => {
  let timeToComplete = 0;
  questions?.forEach((q) => {
    if (
      q.answerType === "multi-media" ||
      q.answerType === "video" ||
      q.answerType === "audio" ||
      q.answerType === "text"
    )
      timeToComplete += 2;
    else if (q.answerType === "interview")
      timeToComplete += q.questionTotal * 2 + 2;
    else timeToComplete += 0.25;
  });
  timeToComplete = Math.ceil(timeToComplete);
  return timeToComplete;
};

export const getPermission = (questions) => {
  const bothAV = { audio: false, video: false };
  for (const item of questions) {
    if (["audio", "multi-media", "interview"].includes(item.answerType)) {
      bothAV.audio = true;
      bothAV.video = false;
    } else if (item.answerType === "video") {
      bothAV.audio = true;
      bothAV.video = true;
      break;
    }
  }
  return bothAV;
};

export const determinePermissions = (questionnaires) => {
  // Initialize permissions result
  let result = {
    video: "not needed",
    audio: "not needed",
  };

  questionnaires.forEach((question) => {
    const { answerType, answers, isSkippable } = question;

    // Temporary variables for current question permissions
    let audioPermission = "not needed";
    let videoPermission = "not needed";

    if (["multi-media", "audio", "video", "interview"].includes(answerType)) {
      if (isSkippable) {
        // Skippable questions: permissions are optional
        if (answerType === "audio") {
          audioPermission = "option";
        }
        if (["video", "interview"].includes(answerType)) {
          audioPermission = "option";
          videoPermission = "option";
        }
        if (answerType === "multi-media") {
          if (answers.includes("audio")) audioPermission = "option";
          if (answers.includes("video")) videoPermission = "option";
        }
      } else {
        // Non-skippable questions
        if (answerType === "audio") {
          audioPermission = "must";
        }
        if (answerType === "interview") {
          audioPermission = "must";
          videoPermission = "option"; // Video is optional for non-skippable interviews
        }
        if (answerType === "multi-media") {
          if (answers.includes("text")) {
            // If text is included, both are optional
            audioPermission = "option";
            videoPermission = "option";
          } else {
            // Text is not included; check audio and video presence
            if (answers.includes("audio") && answers.includes("video")) {
              audioPermission = "must";
              videoPermission = "option"; // Audio is must, video is optional
            } else if (answers.includes("audio")) {
              audioPermission = "must";
            } else if (answers.includes("video")) {
              audioPermission = "must"; // Both are must if only video is present
              videoPermission = "must";
            }
          }
        }
        if (answerType === "video") {
          audioPermission = "must";
          videoPermission = "must";
        }
      }
    }

    // Update the global permissions based on the current question's permissions
    if (audioPermission === "must" || result.audio === "must") {
      result.audio = "must";
    } else if (audioPermission === "option" && result.audio === "not needed") {
      result.audio = "option";
    }

    if (videoPermission === "must" || result.video === "must") {
      result.video = "must";
    } else if (videoPermission === "option" && result.video === "not needed") {
      result.video = "option";
    }
  });

  return result;
};

export const requestPermissions = async ({ video, audio }) => {
  const results = { audio: null, video: null };

  if (video === "must") {
    // Request both permissions together
    const both = await requestBothPermission();
    results.video = both.granted;
    results.audio = both.granted;
    console.log(both.message);
  } else {
    if (audio === "must" || audio === "option") {
      // Request microphone permission separately
      const microphone = await requestMicrophonePermission();
      results.audio = microphone.granted;
      if (!microphone.granted) results.video = false;
      console.log(microphone.message);
    }

    if (video === "option" && results.audio) {
      // Request camera permission separately
      const camera = await requestCameraPermission();
      results.video = camera.granted;
      console.log(camera.message);
    }
  }

  console.log("Final Permissions Result:", results);
  return results;
};

export const getEligibleQuestions = (questions, permissions) => {
  return questions.filter(({ isSkippable, answerType, answers }) => {
    if (!isSkippable) return true;

    if (
      (answerType === "video" && permissions.video) ||
      (answerType === "audio" && permissions.audio)
    ) {
      return true;
    }

    if (answerType === "multi-media") {
      return answers.some(
        (answer) =>
          answer === "text" ||
          (answer === "video" && permissions?.video) ||
          (answer === "audio" && permissions?.audio)
      );
    }

    return false;
  });
};

export const getEligibleSections = (sections, permissions, questions) => {
  const skipList = sections.map((section) => {
    let skipCount = 0;
    for (let i = section.start; i < section.end; i++) {
      const element = questions[i];
      if (element.isSkippable) {
        const { answerType } = element;
        if (
          (answerType === "video" && !permissions.video) ||
          (answerType === "audio" && !permissions.audio)
        ) {
          skipCount++;
        }

        if (answerType === "multi-media") {
          const answers = element.answers.filter((a) => a !== false); // Filter out false answers
          if (!answers.includes("text")) {
            if (
              (answers.includes("video") && !permissions?.video) ||
              (answers.includes("audio") && !permissions?.audio)
            ) {
              skipCount++;
            }
          }
        }
      }
    }
    return skipCount;
  });

  console.log(skipList);

  return sections.map((section, index) => ({
    ...section,
    start: section.start - skipList.slice(0, index).reduce((a, b) => a + b, 0),
    end: section.end - skipList.slice(0, index + 1).reduce((a, b) => a + b, 0),
  }));
};

export const extractQuestion = (str) => {
  const questions = str.match(/[^.!?]*\?[ \n\r]*/g);
  return questions ? questions.map((q) => q.trim()).join(" ") : "";
};
