import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  Button,
  FormControlLabel,
  TextField,
  Alert,
  Typography,
  LinearProgress,
} from "@mui/material";

import { Progress } from "antd";

// for s3 file upload
import { API, Storage } from "aws-amplify";
import { updateResponseUserDetail } from "../graphql/mutations";

// for camera
import Webcam from "react-webcam";
import ReactPlayer from "react-player/lazy";

// for audio recording
//import MicRecorder from "mic-recorder-to-mp3";
import MicRecorder from "../components/MicRecorder";
// for icons
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";

import CountDownTimer from "../components/CountDownTimer";
import StimulusModal from "../components/stimulusModal";
import CustomSelect from "../components/CustomSelect";
import CustomMultiSelect from "../components/CustomMultiSelect";

import {
  clear,
  getLocalStorage,
  removeItem,
  setLocalStorage,
} from "../Models/data/useLocalStorage";

// css
import Styles from "../assets/styles/styles";
import {
  HeaderText2,
  AnswerText,
  CardContainer,
  Labels,
  NoteText,
  GridContainer,
} from "../assets/styles/sxStyles";
import "../App.css";

import "antd/dist/antd.css";
// import { url } from "../assets/test";
import CustomRating from "../components/CustomRating";
import { OnlyMedia } from "../common/constant";
import { Keys } from "../common/keys";

const cardStyle = {
  // backgroundImage: `url("https://picsum.photos/seed/picsum/500/400")`,
  // backgroundSize : "cover",
  // color: "red",
  padding: "0 1rem 1em 1rem",
};

const NewQuestions = ({ QuestionsData, userData, onSignUp }) => {
  const classes = Styles();

  const [isPortrait, setIsPortrait] = useState(
    window.innerWidth < window.innerHeight
  );
  window.onresize = () => {
    if (window.innerWidth < window.innerHeight && !isPortrait)
      setIsPortrait(!isPortrait);
  };
  const [questions] = useState(QuestionsData);
  const [answersList, setAnswersList] = useState([]);

  // answer Type
  const [isVideo, setIsVideo] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [isText, setIsText] = useState(false);
  const [isRadio, setIsRadio] = useState(false);
  const [isCheckbox, setIsCheckbox] = useState(false);
  const [isRating, setIsRating] = useState(false);
  const [isMultiMedia, setIsMultiMedia] = useState(false);
  const [multiMediaValue, setMultiMediaValue] = useState("");

  const [multimediaOptions, setMultimediaOptions] = useState([]);

  const [isSurveyCompleted, setIsSurveyCompleted] = useState(
    userData.isFinished ? true : false
  );

  const [isTesting, setIsTesting] = useState(false);
  const [isStimulusDisabled, setIsStimulusDisabled] = useState(false);

  // Answer option
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState("");
  const [textValue, setTextValue] = useState("");

  // set prev button
  const [isPrev, setIsPrev] = useState(false);
  const [isLast, setIsLast] = useState(true);

  // camera
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoFilePath, setVideoFilePath] = useState(null);
  const [isPreviewPlaying, setIsPreviewPlaying] = useState(false);

  // Audio
  const [audioFile, setAudioFile] = useState();
  const [blobURL, setBlobUrl] = useState(null);
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const [isAudioPreviewPlaying, setisAudioPreviewPlaying] = useState(false);
  const audioMimeType = "audio/mp3";
  const recorder = useRef(null); //Recorder

  // timer for recording audio
  const hoursMinSecs = { hours: 0, minutes: 0, seconds: 0 };

  // For progress bar
  const [progress, setProgress] = useState(0);
  const [isProgressBar, setIsProgressBar] = useState(false);

  // For prompt messages
  const [promptVideoUrl, setPromptVideoUrl] = useState(null);
  const [isPromptVideoPlaying, setIsPromptVideoPlaying] = useState(false);
  const [PromptAudioUrl, setPromptAudioUrl] = useState("");
  const [isPromptAudioPlaying, setIsPromptAudioPlaying] = useState(false);
  const [PromptImageUrl, setPromptImageUrl] = useState("");

  // for disabling answers
  const [isAnswerDisabled, setIsAnswerDisabled] = useState(true);

  // For open answer
  const [isLastSelectedAnswerOpen, setIsLastSelectedAnswerOpen] =
    useState(false);

  const [isNextEnabled, setIsNextEnabled] = useState(true);

  const [optionText, setOptionText] = useState("");

  const [questionsProgress, setQuestionsProgress] = useState(0);

  useEffect(() => {
    setQuestionsProgress(((index / questions.length) * 100).toFixed(0));
  }, [index]);

  useEffect(() => {
    if (progress === 100) {
      setIsProgressBar(false);
      setProgress(0);
    }
  }, [progress]);

  useEffect(() => {
    if (isCheckbox) {
      setIsNextEnabled(false);
    } else if (isRadio && isLastSelectedAnswerOpen) {
      if (optionText.length > 1) {
        setIsNextEnabled(false);
      } else {
        setIsNextEnabled(true);
      }
    } else if (isRadio && value.length > 1) {
      setIsNextEnabled(false);
    } else if (value != "") {
      setIsNextEnabled(false);
    } else {
      setIsNextEnabled(true);
    }
  }, [
    isLastSelectedAnswerOpen,
    isCheckbox,
    isRadio,
    optionText,
    value,
    setIsNextEnabled,
  ]);

  useEffect(() => {
    setIsStimulusDisabled(false);
    if (questions[index].stimulusType) {
      if (
        (questions[index].promptType === "video" && capturing) ||
        (questions[index].promptType === "audio" && isAudioRecording) ||
        isPromptAudioPlaying ||
        isPromptVideoPlaying ||
        isPreviewPlaying ||
        isAudioPreviewPlaying
      ) {
        setIsStimulusDisabled(true);
      }
    }
  }, [
    capturing,
    isAudioRecording,
    index,
    isPromptAudioPlaying,
    isPromptVideoPlaying,
    isPreviewPlaying,
    isAudioPreviewPlaying,
    questions,
    setIsStimulusDisabled,
  ]);

  useEffect(() => {
    async function fetchMedia() {
      let result = "";
      try {
        result = await Storage.get(questions[index].question, {
          expires: 86400,
        });
      } catch (error) {
        console.error(error);
      }

      switch (questions[index].promptType) {
        case "text":
          document.getElementById("test").innerHTML = questions[index].question;
          break;
        case "video":
          setIsPromptVideoPlaying(true);
          // setPromptVideoUrl(url.video);
          setPromptVideoUrl(result);
          break;
        case "audio":
          setIsPromptAudioPlaying(true);
          // setPromptAudioUrl(url.audio1);
          setPromptAudioUrl(result);
          break;
        case "image":
          setPromptImageUrl(result);
          // setPromptImageUrl(url.image);
          break;

        default:
          break;
      }
    }

    if (isMultiMedia) {
      let medialist = [];

      questions[index].answers.forEach((element) => {
        if (element) {
          medialist.push(element);
        }
      });

      setMultimediaOptions(...[], medialist);
      if (medialist.indexOf("video") > -1) {
        setMultiMediaValue("video");
        setIsVideo(true);
      } else if (medialist.indexOf("audio") > -1) {
        setMultiMediaValue("audio");
        setIsAudio(true);
      } else {
        setMultiMediaValue("text");
        setIsText(true);
      }
    }

    fetchMedia();

    if (questions[index].promptType === "text") {
      document.getElementById("test").innerHTML = questions[index].question;
      setIsAnswerDisabled(false);
    }
  }, [
    index,
    isMultiMedia,
    questions,
    setMultimediaOptions,
    setIsAnswerDisabled,
  ]);

  const checkDependentLastAnswer = (question, value, i) => {
    if (i < questions.length && questions[i].dependencies.length > 0) {
      const dependentData = questions[i].dependencies;
      let isDependent = [];
      for (let i = 0; i < dependentData.length; i++) {
        const answeredData = dependentData[i];
        if (question.title === answeredData.question) {
          if (answeredData.answers.includes(value)) {
            isDependent.push(true);
          } else {
            isDependent.push(false);
          }
        }
      }
      let checker = (arr) => arr.every((v) => v === true);

      if (isDependent.length > 0 && checker(isDependent)) {
        return i;
      } else {
        return checkDependentLastAnswer(question, value, i + 1);
      }
    } else {
      return i;
    }
  };

  const handleChange = (event) => {
    // Add contidtion for submit button
    let lastAnsIndex = 0;
    let curIndex = 0;

    if (!isRating && !isText) {
      const curQuestion = questions.find(
        (x) => x.question === event.target.name
      );

      curIndex = questions.findIndex((object) => {
        return object.title === curQuestion.title;
      });

      let i = checkDependentLastAnswer(
        curQuestion,
        event.target.value,
        curIndex + 1
      );

      if (i >= questions.length) {
        setIsLast(true);
      } else {
        setIsLast(false);
      }

      lastAnsIndex = questions[curIndex].answers.length - 1;
    }

    if (isCheckbox) {
      let checkedArray = [];
      if (value !== "") {
        checkedArray = value.split(";");
      }
      if (event.target.checked) {
        checkedArray.push(event.target.value);
      } else {
        var index = checkedArray.indexOf(event.target.value);
        checkedArray.splice(index, 1);
      }

      if (questions[curIndex].answersOpen) {
        if (checkedArray.includes(questions[curIndex].answers[lastAnsIndex])) {
          setIsLastSelectedAnswerOpen(true);
        } else {
          setIsLastSelectedAnswerOpen(false);
          setOptionText("");
        }
      }

      setValue(checkedArray.join(";"));
    } else if (isRadio) {
      setValue(event.target.value);
      if (questions[curIndex].answersOpen) {
        if (questions[curIndex].answers[lastAnsIndex] === event.target.value) {
          setIsLastSelectedAnswerOpen(true);
        } else {
          setIsLastSelectedAnswerOpen(false);
          setOptionText("");
        }
      }
    } else {
      setValue(event.target.value);
    }
  };

  useEffect(() => {
    let data = getLocalStorage(`answers-${userData.surveyID}`);

    if (Object.keys(userData).length === 0) setIsTesting(true);
    else setIsTesting(false);

    let dataindex = 0;

    if (data !== null) {
      setIndex(data.length);
      setIsPrev(true);
      dataindex = data.length;
      setIsSurveyCompleted(userData.isFinished);
    }
    console.log(questions);
    if (dataindex > 0) {
      dataindex = checkDependancy(dataindex, "add");
    }
    checkForLastQuestion(dataindex + 1);

    if (!(userData.isFinished === true)) setLocalStorage(Keys.User, userData);

    if (userData.answers !== null) {
      setAnswersList(...[], data);
    }
    setAnswerOption(questions[dataindex].answerType);

    if (questions[dataindex].promptType === "text") {
      setIsAnswerDisabled(false);
    } else {
      setIsAnswerDisabled(true);
    }
  }, [questions, userData, setIsAnswerDisabled]);

  const checkForLastQuestion = useCallback(
    (id) => {
      if (id < questions.length && questions[id].dependencies > 0) {
        const i = checkDependancy(id, "add");
        if (i > questions.length) setIsLast(true);
        else setIsLast(false);
      } else if (id >= questions.length - 1) {
        setIsLast(true);
      } else {
        setIsLast(false);
      }
    },
    [questions, setIsLast]
  );

  const checkDependancy = useCallback(
    (i, val) => {
      if (i < questions.length - 1 && questions[i].dependencies.length > 0) {
        const dependentData = questions[i].dependencies;
        let isDependent = [];

        let answerData = getLocalStorage(`answers-${userData.surveyID}`);

        if (answerData !== null && answerData.length > 0) {
          answerData.forEach((element) => {
            for (let i = 0; i < dependentData.length; i++) {
              const answeredData = dependentData[i];
              if (element.question === answeredData.question) {
                if (answeredData.answers.includes(element.answer)) {
                  isDependent.push(true);
                } else {
                  isDependent.push(false);
                }
              }
            }
          });
        }

        let checker = (arr) => arr.every((v) => v === true);

        if (isDependent.length > 0 && checker(isDependent)) {
          return i;
        } else {
          if (val === "add") {
            return checkDependancy(i + 1, "add");
          } else {
            return checkDependancy(i - 1, "sub");
          }
        }
      } else {
        return i;
      }
    },
    [questions, userData.surveyID]
  );

  const setOptionalText = (i, ans) => {
    if (answersList && answersList.length > i) {
      let answerVal = answersList.find(
        (x) => x.question === questions[i].title
      ).answer;

      setValue(answerVal);
      if (questions[i].answersOpen) {
        const answers = questions[i].answers;
        if (questions[i].answerType === "single-Select") {
          if (!answers.includes(answerVal)) {
            setIsLastSelectedAnswerOpen(true);
            setOptionText(answerVal);
            setValue(answers[answers.length - 1]);
          }
        } else if (questions[i].answerType === "multi-Select") {
          const answerOptionsList = questions[i].answers;
          let answersData = [];

          answersData = answerVal.split(";");
          let uncheckedData = [];
          let checkedData = [];
          answersData.forEach((element) => {
            if (!answerOptionsList.includes(element)) {
              uncheckedData.push(element);
            } else {
              checkedData.push(element);
            }
          });

          if (uncheckedData.length > 0 && uncheckedData[0] !== "") {
            checkedData.push(answerOptionsList[answerOptionsList.length - 1]);
            setValue(checkedData.join(";"));
            setIsLastSelectedAnswerOpen(true);
            setOptionText(uncheckedData.join(","));
          }
        }
      }
    }
  };

  const resetMediaOptions = () => {
    setIsPromptAudioPlaying(false);
    setIsPromptVideoPlaying(false);
    setIsPreviewPlaying(false);
    setisAudioPreviewPlaying(false);
    setValue("");
    setOptionText("");
    setVideoFilePath(null);
    setIsMultiMedia(false);
    setIsLastSelectedAnswerOpen(false);

    // reset Audio
    setBlobUrl(null);
    setIsAudioRecording(false);
    setIsStimulusDisabled(false);
  };

  const nextQuestion = useCallback(
    async (filename) => {
      let answerData = [];
      let valueString = value;
      if (isLastSelectedAnswerOpen) {
        if (isCheckbox) {
          const lastAnswerOptionsString =
            questions[index].answers[questions[index].answers.length - 1];
          const islastSelected = value
            .split(";")
            .includes(lastAnswerOptionsString);
          if (islastSelected) {
            if (optionText !== "") {
              const stringText = optionText.split(",");
              let vlarr = value.split(";").concat(stringText);
              vlarr = vlarr.filter((e) => e !== lastAnswerOptionsString);
              valueString = vlarr.join(";");
            } else {
              valueString = "";
            }
          }
        } else {
          valueString = optionText;
        }
      }

      if (answersList) {
        answerData = answersList;
        if (answersList.length > index) {
          answersList.forEach((obj) => {
            if (obj.question === questions[index].title) {
              obj.answer =
                typeof filename === "string" ? filename : valueString;
            }

            let ansD = getLocalStorage(`answers-${userData.surveyID}`);
            const answerListVal = ansD.find(function (element) {
              return element.question === questions[index].title;
            });

            let val = value;
            if (questions[index].answersOpen) {
              const ansList = questions[index].answers;
              if (questions[index].answerType === "single-Select") {
                if (ansList[ansList.length - 1] === value) {
                  val = optionText;
                }
              } else {
                const valList = value.split(";");
                if (valList.includes(ansList[ansList.length - 1])) {
                  valList.filter(function (ele) {
                    return ele != ansList[ansList.length - 1];
                  });
                  valList.push(optionText);
                  val = valList.join(";");
                }
              }
            }

            if (answerListVal.answer !== val) {
              var found = answerData.findIndex(function (element) {
                return element.question === questions[index].title;
              });
              answersList.splice(found + 1);
            }
          });

          answerData = answersList;
        } else {
          answerData.push({
            question: questions[index].title,
            answer: typeof filename === "string" ? filename : valueString,
          });
        }
      } else {
        answerData.push({
          question: questions[index].title,
          answer: typeof filename === "string" ? filename : valueString,
        });
      }

      setLocalStorage(`answers-${userData.surveyID}`, answerData);

      setAnswersList(...[], answerData);

      if (index !== questions.length - 1) {
        let i = checkDependancy(index + 1, "add");

        if (i >= questions.length) {
          i -= 1;
        }
        setIndex(i);
        resetMediaOptions();
        setAnswerOption(questions[i].answerType);
        setPrevButton(i);
        checkForLastQuestion(i);
        setOptionalText(i, answersList);
      }
    },
    [
      answersList,
      index,
      questions,
      userData,
      value,
      checkDependancy,
      isLastSelectedAnswerOpen,
      optionText,
    ]
  );

  const onSubmit = useCallback(
    async (filename) => {
      let answerData = [];

      if (answersList) {
        answerData = [...answersList];
      }

      answerData.push({
        question: index,
        answer: typeof filename === "string" ? filename : value,
      });

      let response = {
        emailHash: userData.emailHash,
        surveyID: userData.surveyID,
        answers: JSON.stringify(
          answerData.map((a, i) => {
            return {
              question: questions[i].title,
              answer: a.answer,
              isInS3: OnlyMedia.includes(questions[i].answerType),
            };
          })
        ),
        isFinished: true,
      };

      console.log(JSON.parse(response.answers));

      if (!isTesting) {
        try {
          const updateResponse = await API.graphql({
            query: updateResponseUserDetail,
            variables: { input: response },
            authMode: "AWS_IAM",
          });

          clear();
          setIsSurveyCompleted(true);
        } catch (error) {
          console.error(error);
        }
      } else {
        clear();
        setIsSurveyCompleted(true);
      }
    },
    [answersList, index, questions, userData, value, isTesting]
  );

  const prevQuestion = () => {
    resetMediaOptions();
    let i = checkDependancy(index - 1, "sub");
    setIsMultiMedia(false);
    setIndex(i);
    setPrevButton(i);

    let answerVal = answersList.find(
      (x) => x.question === questions[i].title
    ).answer;

    setValue(answerVal);

    setAnswerOption(questions[i].answerType);
    setIsLastSelectedAnswerOpen(false);
    setOptionText("");
    setOptionalText(i, answersList);
    checkForLastQuestion(i);
  };

  const setPrevButton = (value) => {
    if (value > 0) {
      setIsPrev(true);
    } else {
      setIsPrev(false);
    }
  };

  const setAnswerOption = (val) => {
    setIsCheckbox(false);
    setIsAudio(false);
    setIsRadio(false);
    setIsText(false);
    setIsVideo(false);
    setIsRating(false);

    switch (val) {
      case "video":
        setIsVideo(true);
        break;
      case "audio":
        setIsAudio(true);
        break;
      case "text":
        setIsText(true);
        break;
      case "single-Select":
        setIsRadio(true);
        break;
      case "multi-Select":
        setIsCheckbox(true);
        break;
      case "rating":
        setIsRating(true);
        break;
      case "multi-media":
        setIsMultiMedia(true);
        break;
      default:
        break;
    }
  };

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));

        const video = new Blob([data], {
          type: "video/mp4",
        });
        const url = window.URL.createObjectURL(video);
        setVideoFilePath(url);
        console.log(url);
      }
    },
    [setRecordedChunks, setVideoFilePath]
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    setIsPromptVideoPlaying(false);
    setIsPromptAudioPlaying(false);
    console.log(webcamRef);

    var options = { mimeType: "video/mp4" };
    var iphoneOptions = {
      mimeType: "video/mp4",
      audioBitsPerSecond: 190000,
      videoBitsPerSecond: 1000000,
    };

    var isChrome = navigator.userAgent.indexOf("Chrome") !== -1;

    var isFirefox = typeof InstallTrigger !== "undefined";

    console.log(navigator.userAgent);
    if (
      /(iPad|iPhone|iPod|Macintosh)/g.test(navigator.userAgent) &&
      !isChrome &&
      !isFirefox
    ) {
      mediaRecorderRef.current = new MediaRecorder(
        webcamRef.current.stream,
        iphoneOptions
      );
    } else {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        options,
      });
    }

    console.log(mediaRecorderRef);

    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    setCapturing(false);
    setIsPreviewPlaying(false);
    mediaRecorderRef.current.stop();
  }, [mediaRecorderRef, setCapturing]);

  const handleDownload = useCallback(async () => {
    const newFileName = `${userData.surveyID}/${questions[index].title}-${userData.firstName}${userData.lastInitial}`;

    console.log(recordedChunks);

    const file = {
      // `uri` can also be a file system path (i.e. file://)
      uri: new File(recordedChunks, newFileName),
      name: newFileName + ".mp4",
      type: "video/mp4",
    };

    console.log(file);

    if (!isTesting) {
      try {
        setIsProgressBar(true);
        const result = await Storage.put(file.name, file.uri, {
          contentType: "video/mp4", // contentType is optional
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            setProgress((progress.loaded / progress.total) * 100);
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
        console.log(result);
        setRecordedChunks([]);
        setValue(result.key); //was file.name.
        if (index !== questions.length - 1) {
          nextQuestion(result.key);
        } else {
          onSubmit(result.key);
        }
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    } else {
      setRecordedChunks([]);
      setValue(file.name); //was file.name.
      if (index !== questions.length - 1) {
        nextQuestion(file.name);
      } else {
        onSubmit(file.name);
      }
    }
  }, [
    recordedChunks,
    index,
    questions,
    userData,
    nextQuestion,
    onSubmit,
    isTesting,
  ]);

  // Audio recorder
  useEffect(() => {
    const initializeRecorder = async () => {
      try {
        await recorder.current.init();
      } catch (error) {
        console.error("Error initializing recorder: ", error);
        // Handle the error appropriately
      }
    };
    //Declares the recorder object and stores it in ref
    recorder.current = new MicRecorder({ bitRate: 128 });
    initializeRecorder();
  }, []);

  const startRecording = () => {
    recorder.current.start();
    setIsAudioRecording(true);
    setIsPromptAudioPlaying(false);
    setIsPromptVideoPlaying(false);
  };

  const stopRecording = () => {
    setIsAudioRecording(false);
    recorder.current
      .stop()
      .then((blob) => {
        const newBlobUrl = URL.createObjectURL(blob); //generates url from blob
        setBlobUrl(newBlobUrl); //refreshes the page
        const newFileName = `${userData.surveyID}/${questions[index].title}-${userData.firstName}${userData.lastInitial}.mp3-`;
        setAudioFile(blobToFile(blob, newFileName));
      })
      .catch((e) => console.log(e));
  };

  function blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  const onAudioSubmit = async () => {
    const newFileName = `${userData.surveyID}/${questions[index].title}-${userData.firstName}${userData.lastInitial}.mp3`;

    if (!isTesting) {
      try {
        setIsProgressBar(true);
        const result = await Storage.put(newFileName, audioFile, {
          contentType: audioMimeType, // contentType is optional
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            setProgress((progress.loaded / progress.total) * 100);
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
        setValue(result.key); //was file.name
        if (index !== questions.length - 1) {
          nextQuestion(result.key);
        } else {
          onSubmit(result.key);
        }
        console.log(result);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    } else {
      setValue(newFileName); //was file.name
      if (index !== questions.length - 1) {
        nextQuestion(newFileName);
      } else {
        onSubmit(newFileName);
      }
    }
  };

  const onTextSubmit = async () => {
    const newFileName = `${userData.surveyID}/${questions[index].title}-${userData.firstName}${userData.lastInitial}.txt`;

    if (!isTesting) {
      try {
        setIsProgressBar(true);
        const result = await Storage.put(newFileName, textValue, {
          contentType: "text/plain", // contentType is optional
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            setProgress((progress.loaded / progress.total) * 100);
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
        console.log(result);
        setValue(result.key); //was file.name
        setTextValue("");
        if (index !== questions.length - 1) {
          nextQuestion(result.key);
        } else {
          onSubmit(result.key);
        }
        console.log(result);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    } else {
      setValue(newFileName); //was file.name
      setTextValue("");
      if (index !== questions.length - 1) {
        nextQuestion(newFileName);
      } else {
        onSubmit(newFileName);
      }
    }
  };

  const promptPlayerControl = useCallback(() => {
    setIsPromptVideoPlaying(true);
    setIsPreviewPlaying(false);
    setisAudioPreviewPlaying(false);
    if (capturing || isAudioRecording) {
      setIsPromptVideoPlaying(false);
    }
  }, [setIsPromptVideoPlaying, capturing, isAudioRecording]);

  const promptAudioPlayerControl = useCallback(
    (event) => {
      console.log("audiocontrol", isPromptAudioPlaying);
      setIsPromptAudioPlaying(true);
      setIsPreviewPlaying(false);
      setisAudioPreviewPlaying(false);
      if (capturing || isAudioRecording) {
        setIsPromptAudioPlaying(false);
      }
    },
    [setIsPromptAudioPlaying, capturing, isAudioRecording]
  );

  const strToPascalCase = (str) => {
    var string = str.toString();
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (isSurveyCompleted) {
    removeItem(`answers-${userData.surveyID}`);
    removeItem(`user-${userData.surveyID}`);
    return (
      <div className="card" style={cardStyle}>
        <div className="container">
          <Typography sx={HeaderText2}>
            Finished! Thank you for completing the survey.
          </Typography>
          {onSignUp && (
            <div>
              <Typography sx={HeaderText2}>
                Would you like to register in our database of panelists for
                future opportunities to be recruited in paid studies?
              </Typography>
              <Typography sx={{ m: 1 }}>&nbsp;</Typography>
              <Button
                className={classes.centeredButton}
                variant="contained"
                onClick={onSignUp}
              >
                <Typography sx={AnswerText}>
                  Register me for future paid studies opportunity
                </Typography>
              </Button>
              <Typography sx={{ m: 1 }}>&nbsp;</Typography>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        {!isPortrait && (
          <Grid item xs={2} alignItems="center">
            {isPrev ? (
              <Button
                className={classes.centeredButton}
                variant="contained"
                onClick={prevQuestion}
              >
                <Typography sx={AnswerText}>Prev</Typography>
              </Button>
            ) : (
              <div></div>
            )}
          </Grid>
        )}
        <Grid item xs="auto">
          <Card sx={CardContainer}>
            <Grid container>
              <Grid item xs={12} paddingRight={"15px"}>
                <Progress
                  strokeLinecap="butt"
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  }}
                  percent={questionsProgress}
                />
              </Grid>
            </Grid>

            <CardContent style={cardStyle}>
              {questions.length > 0 ? (
                <div className={classes.root}>
                  <Grid container>
                    <Grid item xs={12} padding={0}>
                      <FormControl
                        component="fieldset"
                        style={{ gap: "0.75em" }}
                        fullWidth
                      >
                        <Grid item xs={12} className="prompt-video-container">
                          {questions[index].promptType === "text" && (
                            <Grid item xs={12}>
                              <div id="test"></div>
                            </Grid>
                          )}

                          {questions[index].promptType === "video" && (
                            <ReactPlayer
                              controls
                              playsinline={true}
                              playing={isPromptVideoPlaying}
                              onPlay={promptPlayerControl}
                              onBufferEnd={() => setIsAnswerDisabled(true)}
                              onPause={() => setIsPromptVideoPlaying(false)}
                              onError={() => setIsPromptVideoPlaying(false)}
                              onEnded={() => setIsAnswerDisabled(false)}
                              className={
                                isPromptVideoPlaying || isAnswerDisabled
                                  ? "prompt-video"
                                  : "none"
                              }
                              width={
                                isPromptVideoPlaying || isAnswerDisabled
                                  ? "100%"
                                  : "60%"
                              }
                              height={
                                isPromptVideoPlaying || isAnswerDisabled
                                  ? "100%"
                                  : "60%"
                              }
                              url={promptVideoUrl}
                            />
                          )}

                          {questions[index].promptType === "audio" && (
                            <ReactPlayer
                              playsinline={true}
                              className="audio-prompt-container"
                              onPlay={promptAudioPlayerControl}
                              onPause={() => setIsPromptAudioPlaying(false)}
                              onBufferEnd={() => setIsAnswerDisabled(true)}
                              onError={() => setIsPromptAudioPlaying(false)}
                              onEnded={() => setIsAnswerDisabled(false)}
                              width="300px"
                              height="50px"
                              url={PromptAudioUrl}
                              playing={isPromptAudioPlaying}
                              controls
                            />
                          )}

                          {questions[index].promptType === "image" && (
                            <img
                              src={PromptImageUrl}
                              alt={questions[index].title}
                              width="100%"
                              height="100%"
                            />
                          )}
                        </Grid>

                        {questions[index].marquee.length > 0 && (
                          <Grid item xs={12}>
                            <Alert severity="info">
                              {questions[index].marquee}
                            </Alert>
                          </Grid>
                        )}

                        {questions[index].stimulusType && (
                          <Grid item xs={12}>
                            <StimulusModal
                              type={strToPascalCase(
                                questions[index].stimulusType
                              )}
                              url={questions[index].stimulus}
                              allow={isStimulusDisabled}
                            />
                          </Grid>
                        )}

                        {isMultiMedia && (
                          <RadioGroup
                            aria-label={questions[index].question}
                            name={questions[index].question}
                            value={multiMediaValue}
                          >
                            <Typography sx={Labels}>
                              Please select one option to answer the above
                              question
                            </Typography>
                            <Grid container>
                              {multimediaOptions.map((item, i) => {
                                return (
                                  <Grid item xs={6} sm={4} key={i}>
                                    <FormControlLabel
                                      value={item.toString().trim()}
                                      control={<Radio />}
                                      disabled={isAnswerDisabled}
                                      label={
                                        <Typography sx={AnswerText}>
                                          {strToPascalCase(item.toString())}
                                        </Typography>
                                      }
                                      onChange={(e) => {
                                        setMultiMediaValue(e.target.value);
                                        setAnswerOption(e.target.value);
                                      }}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </RadioGroup>
                        )}

                        {isRadio && (
                          <Grid
                            item
                            xs={12}
                            width="100%"
                            style={{ gap: "2em" }}
                          >
                            <CustomSelect
                              onChange={handleChange}
                              data={questions[index]}
                              value={value}
                              disabled={isAnswerDisabled}
                            />
                            {isLastSelectedAnswerOpen && (
                              <div className="option-text">
                                <TextField
                                  id="outlined-basic"
                                  label=" "
                                  InputLabelProps={{ shrink: false }}
                                  size="small"
                                  name="optionText"
                                  value={optionText}
                                  disabled={isAnswerDisabled}
                                  placeholder="Please enter your option"
                                  onChange={(e) =>
                                    setOptionText(e.target.value)
                                  }
                                  variant="outlined"
                                />
                              </div>
                            )}
                          </Grid>
                        )}

                        {isText && (
                          <Grid item xs={12} width={"100%"}>
                            <TextField
                              id="outlined-multiline-flexible"
                              name={questions[index].question}
                              multiline
                              disabled={isAnswerDisabled}
                              label="Enter you answer"
                              inputProps={{ style: AnswerText }}
                              className="text-area"
                              placeholder="Enter your text here"
                              maxRows={10}
                              value={textValue}
                              onChange={(e) => setTextValue(e.target.value)}
                            />
                          </Grid>
                        )}

                        {isRating && (
                          <Grid container>
                            <CustomRating
                              onChange={handleChange}
                              data={questions[index]}
                              value={value}
                              disabled={isAnswerDisabled}
                            />
                          </Grid>
                        )}

                        {isCheckbox && (
                          <Grid item xs={12} width={"100%"}>
                            <CustomMultiSelect
                              onChange={handleChange}
                              data={questions[index]}
                              value={value}
                              disabled={isAnswerDisabled}
                            />

                            {isLastSelectedAnswerOpen && (
                              <div className="option-text">
                                <label>
                                  For multiple answer seperate the words by
                                  comma ,
                                </label>

                                <TextField
                                  id="outlined-basic"
                                  label=" "
                                  InputLabelProps={{ shrink: false }}
                                  size="small"
                                  name="optionText"
                                  value={optionText}
                                  onChange={(e) =>
                                    setOptionText(e.target.value)
                                  }
                                  variant="outlined"
                                />
                              </div>
                            )}
                          </Grid>
                        )}

                        {isVideo && (
                          <Grid
                            item
                            xs={12}
                            width={"100%"}
                            className="answer-container"
                          >
                            {recordedChunks.length > 0 ? (
                              <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                rowSpacing={2}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  height={"100%"}
                                  className="prompt-video-container"
                                >
                                  {questions[index].promptType === "video" ? (
                                    <ReactPlayer
                                      controls
                                      playsinline={true}
                                      playing={isPreviewPlaying}
                                      onPlay={() => {
                                        setIsPromptVideoPlaying(false);
                                        setIsPreviewPlaying(true);
                                      }}
                                      onPause={() => setIsPreviewPlaying(false)}
                                      width={
                                        !isPromptVideoPlaying ? "100%" : "60%"
                                      }
                                      height={
                                        !isPromptVideoPlaying ? "100%" : "60%"
                                      }
                                      className="video"
                                      url={videoFilePath}
                                    />
                                  ) : (
                                    <ReactPlayer
                                      controls
                                      playsinline={true}
                                      playing={isPreviewPlaying}
                                      onPlay={() => {
                                        setIsPromptAudioPlaying(false);
                                        setIsPreviewPlaying(true);
                                      }}
                                      onPause={() => setIsPreviewPlaying(false)}
                                      className="video"
                                      url={videoFilePath}
                                    />
                                  )}
                                </Grid>

                                <Grid item xs={12} width={"100%"}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Grid item xs="auto">
                                      <Button
                                        variant="outlined"
                                        color="success"
                                        onClick={() => {
                                          setRecordedChunks([]);
                                          setIsPromptVideoPlaying(false);
                                        }}
                                        startIcon={
                                          <AutorenewOutlinedIcon
                                            style={{ fontSize: 20 }}
                                          />
                                        }
                                        size="small"
                                      >
                                        <Typography sx={AnswerText}>
                                          Re{`${String.fromCodePoint(8209)}`}do
                                        </Typography>
                                      </Button>
                                    </Grid>

                                    <Grid item xs="auto">
                                      <Button
                                        variant="outlined"
                                        onClick={() => {
                                          setIsPreviewPlaying(
                                            !isPreviewPlaying
                                          );
                                          if (isPreviewPlaying) {
                                            setIsPromptVideoPlaying(false);
                                          }
                                        }}
                                        startIcon={
                                          <PlayCircleFilledWhiteOutlinedIcon
                                            style={{ fontSize: 20 }}
                                          />
                                        }
                                        size="small"
                                      >
                                        <Typography sx={AnswerText}>
                                          Preview
                                        </Typography>
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography sx={NoteText}>
                                    Note: Click on record to start recording
                                  </Typography>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid
                                container
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                rowSpacing={2}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  height={"100%"}
                                  className="prompt-video-container"
                                >
                                  {questions[index].promptType === "video" ? (
                                    <Webcam
                                      audio={true}
                                      muted={true}
                                      ref={webcamRef}
                                      width={
                                        !isAnswerDisabled &&
                                        !isPromptVideoPlaying
                                          ? "100%"
                                          : "60%"
                                      }
                                      height={
                                        !isAnswerDisabled &&
                                        !isPromptVideoPlaying
                                          ? "100%"
                                          : "60%"
                                      }
                                    />
                                  ) : (
                                    <Webcam
                                      audio={true}
                                      muted={true}
                                      ref={webcamRef}
                                      width="100%"
                                    />
                                  )}
                                </Grid>

                                <Grid item xs={12}>
                                  {capturing ? (
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={handleStopCaptureClick}
                                      startIcon={
                                        <StopCircleOutlinedIcon
                                          style={{ fontSize: 20 }}
                                        />
                                      }
                                      size="small"
                                    >
                                      <Typography sx={AnswerText}>
                                        Stop
                                      </Typography>
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      color="success"
                                      startIcon={
                                        <RadioButtonCheckedOutlinedIcon
                                          style={{ fontSize: 20 }}
                                        />
                                      }
                                      size="small"
                                      disabled={isAnswerDisabled}
                                      onClick={handleStartCaptureClick}
                                    >
                                      <Typography sx={AnswerText}>
                                        Record
                                      </Typography>
                                    </Button>
                                  )}
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography sx={NoteText}>
                                    Note: Click on record to start recording
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        )}

                        {isAudio && (
                          <div className="width-100">
                            {blobURL ? (
                              <div className="width-100">
                                <div className="audio-container">
                                  <ReactPlayer
                                    playsinline={true}
                                    className="audio-prompt-container"
                                    width="300px"
                                    height="50px"
                                    playing={isAudioPreviewPlaying}
                                    url={blobURL}
                                    onPlay={() => {
                                      setisAudioPreviewPlaying(true);
                                      setIsPromptAudioPlaying(false);
                                      setIsPromptVideoPlaying(false);
                                    }}
                                    onPause={() =>
                                      setisAudioPreviewPlaying(false)
                                    }
                                    controls
                                  />
                                </div>

                                <div className="button-container">
                                  <Button
                                    variant="outlined"
                                    color="success"
                                    startIcon={
                                      <AutorenewOutlinedIcon
                                        style={{ fontSize: 20 }}
                                      />
                                    }
                                    size="small"
                                    onClick={() => {
                                      setBlobUrl(null);
                                      setIsAudioRecording(false);
                                    }}
                                  >
                                    <Typography sx={AnswerText}>
                                      Re{`${String.fromCodePoint(8209)}`}do
                                    </Typography>
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <div>
                                {/* Display timer */}
                                {isAudioRecording ? (
                                  <Grid item xs={12} sx={GridContainer}>
                                    <CountDownTimer
                                      hoursMinSecs={hoursMinSecs}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid item xs={12} sx={GridContainer}>
                                    <div>
                                      <span className="timer-text">00:00</span>
                                    </div>
                                  </Grid>
                                )}

                                <div className="button-container">
                                  {!isAudioRecording ? (
                                    <Button
                                      variant="outlined"
                                      color="success"
                                      startIcon={
                                        <RadioButtonCheckedOutlinedIcon
                                          style={{ fontSize: 20 }}
                                        />
                                      }
                                      onClick={startRecording}
                                      disabled={isAnswerDisabled}
                                      size="small"
                                    >
                                      <Typography sx={AnswerText}>
                                        Record
                                      </Typography>
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={stopRecording}
                                      startIcon={
                                        <StopCircleOutlinedIcon
                                          style={{ fontSize: 20 }}
                                        />
                                      }
                                      size="small"
                                    >
                                      <Typography sx={AnswerText}>
                                        Stop
                                      </Typography>
                                    </Button>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {isProgressBar && progress > 1 && (
                    <Grid xs={12} className="progress-bar">
                      <LinearProgress variant="determinate" value={progress} />
                    </Grid>
                  )}
                </div>
              ) : null}
            </CardContent>
            {isPortrait && (
              <CardActions className={classes.cardActionCustom}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {isPrev ? (
                    <Button variant="contained" onClick={prevQuestion}>
                      <Typography sx={AnswerText}>Prev</Typography>
                    </Button>
                  ) : (
                    <div></div>
                  )}

                  {!isLast ? (
                    <div>
                      {isVideo || isAudio || isText ? (
                        <Button
                          variant="contained"
                          onClick={() => {
                            if (isVideo) {
                              handleDownload();
                            } else if (isAudio) {
                              onAudioSubmit();
                            } else {
                              onTextSubmit();
                            }
                          }}
                          disabled={
                            recordedChunks.length > 0 ||
                            blobURL !== null ||
                            textValue.length > 0
                              ? false
                              : true
                          }
                        >
                          <Typography sx={AnswerText}>Next</Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={nextQuestion}
                          disabled={isNextEnabled}
                        >
                          <Typography sx={AnswerText}>Next</Typography>
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div>
                      {isVideo || isAudio || isText ? (
                        <Button
                          variant="contained"
                          onClick={() => {
                            if (isVideo) {
                              handleDownload();
                            } else if (isAudio) {
                              onAudioSubmit();
                            } else {
                              onTextSubmit();
                            }
                          }}
                          disabled={
                            recordedChunks.length > 0 ||
                            blobURL !== null ||
                            textValue.length > 0
                              ? false
                              : true
                          }
                        >
                          <Typography sx={AnswerText}>Submit</Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={onSubmit}
                          disabled={isNextEnabled}
                        >
                          <Typography sx={AnswerText}>Submit</Typography>
                        </Button>
                      )}
                    </div>
                  )}
                </Grid>
              </CardActions>
            )}
          </Card>
        </Grid>
        {!isPortrait && (
          <Grid item xs={2}>
            {!isLast ? (
              <div>
                {isVideo || isAudio || isText ? (
                  <Button
                    className={classes.centeredButton}
                    variant="contained"
                    onClick={() => {
                      if (isVideo) {
                        handleDownload();
                      } else if (isAudio) {
                        onAudioSubmit();
                      } else {
                        onTextSubmit();
                      }
                    }}
                    disabled={
                      recordedChunks.length > 0 ||
                      blobURL !== null ||
                      isProgressBar ||
                      textValue.length > 0
                        ? false
                        : true
                    }
                  >
                    <Typography sx={AnswerText}>Next</Typography>
                  </Button>
                ) : (
                  <Button
                    className={classes.centeredButton}
                    variant="contained"
                    onClick={nextQuestion}
                    disabled={isNextEnabled}
                  >
                    <Typography sx={AnswerText}>Next</Typography>
                  </Button>
                )}
              </div>
            ) : (
              <div>
                {isVideo || isAudio || isText ? (
                  <Button
                    className={classes.centeredButton}
                    variant="contained"
                    onClick={() => {
                      if (isVideo) {
                        handleDownload();
                      } else if (isAudio) {
                        onAudioSubmit();
                      } else {
                        onTextSubmit();
                      }
                    }}
                    disabled={
                      recordedChunks.length > 0 ||
                      blobURL !== null ||
                      isProgressBar ||
                      textValue.length > 0
                        ? false
                        : true
                    }
                  >
                    <Typography sx={AnswerText}>Submit</Typography>
                  </Button>
                ) : (
                  <Button
                    className={classes.centeredButton}
                    variant="contained"
                    onClick={onSubmit}
                    disabled={value !== "" ? false : isCheckbox ? false : true}
                  >
                    <Typography sx={AnswerText}>Submit</Typography>
                  </Button>
                )}
              </div>
            )}
          </Grid>
        )}
      </Grid>
    );
  }
};

export default NewQuestions;
