import React, { useEffect, useState } from "react";
import { Grid, Typography, Modal, Divider, Button } from "@mui/material";
import {
  modalStyle,
  surveyHeaderText,
  surveyText,
  contentStart,
  svgStyle,
  contentSpaceBetween,
  contentStartRow,
  buttonStyle,
  AnswerText,
} from "../assets/styles/sxStyles";
import WarningIcon from "./../assets/icons/Warning.svg";
import CustomButton from "./CustomButton";

const CustomModal = ({ enabled, handleClose, handleAccept }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(enabled);
  }, [enabled]);

  return (
    <Modal
      open={open}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Grid container sx={{ ...modalStyle, width: "50em", maxWidth: "80vw" }}>
        <Grid container sx={contentStart} gap={"1.05rem"}>
          <Grid item xs={12}>
            <Typography
              sx={{ ...contentStartRow, ...surveyHeaderText }}
              gap={"0.5rem"}
            >
              <img src={WarningIcon} alt="Warning Icon" style={svgStyle} />
              <b>Important Notice</b>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography sx={surveyText}>
              You are about to proceed to the Virtual Interview section.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography sx={surveyText}>
              Please note: Once you continue, all previous sections will be
              locked and you will not be able to edit your responses.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography sx={surveyText}>
              Are you sure you want to proceed?
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} sx={contentSpaceBetween}>
            <CustomButton
              text={"Go Back"}
              variant={"outlined"}
              icon={""}
              disabled={false}
              color={"gray"}
              handleclick={handleClose}
            />

            {/* <CustomButton
              text={"Continue to Virtual Interview"}
              variant={"contained"}
              icon={""}
              disabled={false}
              color={"primary"}
              handleclick={handleAccept}
            /> */}

            <Button
              variant="contained"
              color={"primary"}
              sx={buttonStyle}
              onClick={handleAccept}
              disabled={false}
              size="small"
            >
              <Typography sx={AnswerText}>
                Continue to Virtual Interview
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CustomModal;
