import React, { useEffect, useState } from "react";
import { Grid, Typography, Modal, Divider } from "@mui/material";
import {
  modalStyle,
  surveyHeaderText,
  surveyText,
  surveySubtitleText,
  contentCenter,
  contentStart,
  svgStyle,
  contentSpaceBetween,
  contentEnd,
  surveyParaText,
} from "../assets/styles/sxStyles";
import PermissionError from "./PermissionError";
import {
  determinePermissions,
  formatDate,
  getPermission,
  getTimeToComplete,
  requestPermissions,
} from "../common/util";
import {
  getLocalStorage,
  setLocalStorage,
} from "../Models/data/useLocalStorage";
import { Keys } from "../common/keys";
import TimerIcon from "./../assets/icons/TimerIcon.svg";
import ClockIcon from "./../assets/icons/Clock.svg";
import ShieldCheckIcon from "./../assets/icons/ShieldCheck.svg";
import CustomButton from "./CustomButton";

const SurveyInfo = ({ data, enabled, handleSubmit, handleClose }) => {
  const [open, setOpen] = useState(false);
  const [isPermisionInfo, setPermisionInfo] = useState(false);
  const [isPermissionGranted, setPermission] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [checkPermissions, setCheckPermissions] = useState([]);

  const avPermission = getLocalStorage(Keys.Permission);

  useEffect(() => {
    setOpen(enabled);
  }, [enabled]);

  useEffect(() => {
    if (data && data?.questions) {
      const questionsData = JSON.parse(data.questions);
      setQuestions(questionsData);
      setCheckPermissions(determinePermissions(questionsData));
    }
  }, [data]);

  const checkIfPermissions = (permissions) => {
    console.log(permissions);
    if (
      checkPermissions.video === "must" &&
      !permissions.video &&
      !permissions.audio
    )
      setPermission(false);
    else if (checkPermissions.audio === "must" && !permissions.audio)
      setPermission(false);
    else handleSubmit();
  };

  const handleTakeMeIn = () => {
    setPermisionInfo(true);
    if (avPermission !== null) checkIfPermissions(avPermission);
  };

  const handlePermission = async () => {
    console.log(checkIfPermissions);
    const permissions = await requestPermissions(checkPermissions);
    setLocalStorage(Keys.Permission, permissions);
    checkIfPermissions(permissions);
  };

  const avPermissionString = () => {
    const permissionData = getPermission(questions);
    if (!permissionData.video) return "audio permissions";
    else return "audio and video permissions";
  };

  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Grid container sx={{ ...modalStyle, width: "50em", maxWidth: "80vw" }}>
        {!isPermisionInfo ? (
          <Grid container sx={contentStart} gap={"1.5rem"}>
            <Grid item gap={"1rem"} alignItems={"center"} xs={12}>
              <Grid container sx={contentCenter} gap={"1rem"}>
                <Typography sx={surveyHeaderText}>{data.title}</Typography>
                <Typography sx={surveySubtitleText}>
                  {data.optionalMessage}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={surveyText}>
                <img src={TimerIcon} alt="Timer Icon" style={svgStyle} />
                <b>Survey Duration:</b>&nbsp; Approximately{" "}
                {getTimeToComplete(questions)} minutes
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={surveyText}>
                <img
                  src={ShieldCheckIcon}
                  alt="Shied clock Icon"
                  style={svgStyle}
                />
                <b>Privacy: </b> &nbsp; Your responses will be kept completely
                confidential
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={surveyText}>
                <img src={ClockIcon} alt="Clock Icon" style={svgStyle} />
                <b>Deadline: </b>&nbsp; {formatDate(data.stopDate)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} sx={contentSpaceBetween}>
              <CustomButton
                text={"Go Back"}
                variant={"outlined"}
                icon={""}
                disabled={false}
                color={"gray"}
                handleclick={handleClose}
              />

              <CustomButton
                text={"Begin Survey"}
                variant={"contained"}
                icon={""}
                disabled={false}
                color={"primary"}
                handleclick={handleTakeMeIn}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            {isPermissionGranted === null && (
              <Grid container sx={contentStart} gap={"1.5rem"}>
                <Grid item gap={"1rem"} alignItems={"center"} xs={12}>
                  <Grid container sx={contentCenter} gap={"1rem"}>
                    <Typography sx={surveyHeaderText}>
                      Important Notice
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={surveyParaText}>
                    When you click the button below, the app will request access
                    to your microphone and/or camera. These permissions are
                    needed to complete certain parts of the survey. You can
                    choose to allow or deny access, but please note that denying
                    access may limit your ability to answer specific questions.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} sx={contentEnd}>
                  <CustomButton
                    text={"Proceed"}
                    variant={"contained"}
                    icon={""}
                    disabled={false}
                    color={"primary"}
                    handleclick={handlePermission}
                  />
                </Grid>
              </Grid>
            )}
            {isPermissionGranted === false && (
              <PermissionError data={avPermissionString()} />
            )}
          </>
        )}
      </Grid>
    </Modal>
  );
};

export default SurveyInfo;
