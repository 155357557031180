import React, { useEffect, useState } from "react";
import { Select } from "antd";
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Grid,
} from "@mui/material";
import "antd/dist/antd.css";
import { AnswerText } from "../assets/styles/sxStyles";

import { createEvent } from "../common/util";
import ReorderList from "./ReorderList";

const { Option } = Select;

const CustomMultiSelect = (props) => {
  const [answersList, setAnswersList] = useState([]);
  const [value, setValue] = useState([]);
  const [isRanked, setIsRanked] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setValue([]);
    setItems([]);
    if (props.value) {
      let valData = props.value
        .split(";")
        .filter((e) => props.data.answers.includes(e));
      if (props.value.split(";").length > valData.length + 1)
        valData.push(props.data.answers[props.data.answers.length - 1]);

      setValue(...[], valData);

      if (props.isRankedSelect)
        setItems(
          ...[],
          valData.map((item, id) => ({ id: `item ${id}`, content: item }))
        );
    }

    setAnswersList(props.data.answers);
    setIsRanked(props.isRankedSelect);
  }, [props.data, props.value, setValue]);

  const handleListChange = (reorderedItems) => {
    const dataArr = reorderedItems.map((element) => element.content);
    setValue(...[], dataArr);
    props.onChange(createEvent(props.data.question, dataArr.join(";"), true));
    setItems(reorderedItems);
  };

  const handleChange = (val, event) => {
    const data = [...value];

    let str;
    let add = true;
    if (val.length >= data.length) str = val.filter((e) => !data.includes(e));
    else {
      str = data.filter((e) => !val.includes(e));
      add = false;
    }

    const ansData = val.map((item, id) => ({
      id: `item ${id}`,
      content: item,
    }));
    setItems(...[], ansData);
    setValue(val);
    props.onChange(createEvent(props.data.question, val.join(";"), add));
  };

  const onSelectChange = (event) => {
    let valStr = value;
    if (event.target.checked) valStr.push(event.target.value);
    else valStr = valStr.filter((element) => element !== event.target.value);

    const arrStr = [...new Set(valStr)];
    setValue(arrStr);
    props.onChange(createEvent(props.data.question, arrStr.join(";"), true));
  };

  return (
    <div>
      {answersList.length > 4 ? (
        <Select
          mode="multiple"
          allowClear
          showSearch={answersList.length > 10}
          style={{ width: "100%", overflow: "auto" }}
          disabled={props.disabled}
          placeholder="Please select"
          defaultValue={value}
          value={value}
          onChange={handleChange}
          placement="bottomLeft"
          listItemHeight={10}
          listHeight={200}
        >
          {answersList.map((element, i) => (
            <Option key={i} value={element} className="select-option-text">
              {element}
            </Option>
          ))}
        </Select>
      ) : (
        <FormGroup>
          {answersList.map((item, i) => {
            return (
              <FormControlLabel
                value={item}
                name={props.data.question}
                disabled={props.disabled}
                control={
                  <Checkbox
                    checked={value.includes(item)}
                    onChange={onSelectChange}
                  />
                }
                label={<Typography sx={AnswerText}>{item}</Typography>}
                key={i}
              />
            );
          })}
        </FormGroup>
      )}

      {isRanked && items.length > 0 && (
        <Grid container my={1}>
          <Grid item xs={12} paddingLeft={1}>
            <Typography variant="caption" display="block" gutterBottom>
              Arrange these things from most to least important by moving them
              around:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ReorderList items={items} onChange={(i) => handleListChange(i)} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CustomMultiSelect;
