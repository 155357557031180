import { makeStyles } from "@material-ui/core/styles";

const Styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 4,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  pxLogo: {
    height: "6vh",
    pointerEvents: "none",
  },
  centeredButton: {
    display: "flex !important",
    margin: "0 auto !important",
    minWidth: "100px !important",
  },
  cardActionCustom: {
    padding: "1rem !important",
  },
  audioContainer: {
    width: "75vw",
    maxWidth: "450px",
    backgroundColor: "#f5f5f5",
    borderRadius: "30px",
    border: "10px solid #f5f5f5",
    overflow: "hidden",
  },
}));

export default Styles;
