import { useEffect, useRef } from "react";
import { API } from "aws-amplify";
import { virtualinterviewerclient } from "../../graphql/mutations";

function InitVirtualInterviewer({
  studyId,
  panelist,
  firstName,
  testMode,
  interviewTitle,
  onDone,
}) {
  useEffect(() => {
    onDone(false)
    async function init() {
      API.graphql({
        query: virtualinterviewerclient,
        variables: {
          functionName: "createPanelistForStudy",
          studyId: studyId,
          panelist: panelist,
          params: JSON.stringify({
            firstName: firstName,
            testMode: testMode,
            interviewTitle: interviewTitle,
          }),
        },
        authMode: "AWS_IAM",
      })
        .then((data) => {
          console.log(
            "response",
            JSON.parse(data.data.virtualinterviewerclient)
          );
          const resp = JSON.parse(data.data.virtualinterviewerclient);
          onDone(true);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    init();
    return () => {
      console.log("destroy");
    };
  }, [onDone]);

  return null;
}

export default InitVirtualInterviewer;
