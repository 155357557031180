import React from "react";

import sendIcon from "../assets/icons/send.svg";
import { Button, Typography } from "@mui/material";
import { AnswerText } from "../assets/styles/sxStyles";

const CustomSubmit = ({ disabled, handleclick }) => {
  return (
    <Button
      variant="contained"
      startIcon={<img src={sendIcon} alt="logo" />}
      size="small"
      disabled={disabled}
      onClick={handleclick}
      sx={{
        textTransform: "none",
        fontSize: "0.875rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .MuiButton-startIcon": {
          marginRight: "0.15rem",
        },
      }}
    >
      <Typography sx={AnswerText}>Submit</Typography>
    </Button>
  );
};

export default CustomSubmit;
